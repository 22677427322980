export default function (videojs) {
    const storeKey = "li_pl_res_label";
    function setDefaultResolution(resolutionLabel) {
        try {
            localStorage.setItem(storeKey, resolutionLabel);
        } catch (e) {}
    }
    function getDefaultResolution() {
        try {
            return localStorage.getItem(storeKey);
        } catch (e) {}
        return null;
    }

    const MenuItem = videojs.getComponent("MenuItem");
    class QualityMenuItem extends MenuItem {
        constructor(player, options) {
            super(player, {
                label: options.label,
                selected: options.selected,
                selectable: true,
                multiSelectable: false,
            });
            this.id = options.id;
            this.label = options.label;
            this.on(player, "quality", (e, target) => this.update(e, target));
            if (options.selected) {
                this.changeResolution();
            }
        }
        changeResolution() {
            const id = this.id;
            const qualityList = this.player_.qualityLevels();
            for (let i = 0; i < qualityList.length; i++) {
                qualityList[i].enabled = id === i || id === "auto";
            }
        }
        handleClick(event) {
            super.handleClick();
            setDefaultResolution(this.label);
            this.changeResolution();
            this.player_.trigger("quality", this);
        }
        update(event, target) {
            this.selected(target.id === this.id);
        }
    }

    const MenuButton = videojs.getComponent("MenuButton");
    class QualityButton extends MenuButton {
        constructor(player, options) {
            super(player, options);

            this.updateVisibility();
            // this.on(player, "quality", (e, target) => this.updateLabel(e, target));
            this.on(player, "loadedmetadata", e => this.handleQualityLevelsChange(e));
        }
        // createEl() {
        //     const el = super.createEl();
        //     this.labelElId_ = "vjs-quality-level-value-label-" + this.id_;
        //     this.labelEl_ = videojs.dom.createEl("div", {
        //         className: "vjs-quality-level-value",
        //         id: this.labelElId_,
        //         textContent: "自動",
        //     });
        //     el.appendChild(this.labelEl_);
        //     return el;
        // }
        dispose() {
            // this.labelEl_ = null;
            super.dispose();
        }
        buildCSSClass() {
            return `vjs-quality-level ${super.buildCSSClass()}`;
        }
        buildWrapperCSSClass() {
            return `vjs-quality-level ${super.buildWrapperCSSClass()}`;
        }
        createItems() {
            const player = this.player();
            const hasResolution = {};
            const menuDataList = player
                .qualityLevels()
                .levels_.sort((a, b) => (a.height == b.height ? a.bitrate - b.bitrate : a.height - b.height))
                .map((level, index) => ({ id: index, label: labelMap(level), selected: false }))
                .concat({ id: "auto", label: "自動", selected: false })
                .reverse();
            menuDataList[getDefaultIndex(menuDataList)].selected = true;

            return menuDataList.map(level => new QualityMenuItem(player, level));

            function labelMap(level) {
                const resolution = computeResolution(level);
                if (hasResolution[resolution]) {
                    return resolution + "p+";
                }
                hasResolution[resolution] = true;
                return resolution + "p";
            }
            function getDefaultIndex(menuDataList) {
                const defaultLabel = getDefaultResolution();
                if (!defaultLabel) {
                    return 0; // auto
                }
                const index = menuDataList.findIndex(item => item.label == defaultLabel);
                return index > 0 ? index : 0;
            }
            function computeResolution(level) {
                const reference = Math.min(level.height, level.width);
                if (reference > 720) {
                    return "1080";
                }
                if (reference > 480) {
                    return "720";
                }
                if (reference > 360) {
                    return "480";
                }
                if (reference > 240) {
                    return "360";
                }
                return "240";
            }
        }
        handleQualityLevelsChange() {
            this.update();
            this.updateVisibility();
        }
        qualityLevelSupported() {
            return this.player().qualityLevels && this.player().tech({ IWillNotUseThisInPlugins: true }).vhs;
        }
        updateVisibility(event) {
            if (this.qualityLevelSupported()) {
                this.removeClass("vjs-hidden");
            } else {
                this.addClass("vjs-hidden");
            }
        }
        // updateLabel(event, item) {
        //     if (!this.qualityLevelSupported()) {
        //         return;
        //     }
        //     this.labelEl_.textContent = item.label;
        // }
    }

    const Component = videojs.getComponent("Component");
    Component.registerComponent("QualityMenuItem", QualityMenuItem);
    Component.registerComponent("QualityButton", QualityButton);
}
