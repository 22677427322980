import {mobilecheck} from "../Util.js";
import pustiPath from "./PustiPath.js";

const DEVICE_CATEGORY = ["PPN-EM-desktop", "PPN-EM-mobile"];
export default async function fetchPuidApi(puid){
    let response;
    let options = {
        method: "POST",
        credentials: 'include',
        headers: {
            "Content-Type": "application/json"
        },
        body:JSON.stringify({
            id: (Math.random() * 1000) | 0,
            jsonrpc: "2.0",
            method: "PustiService.PUID",
            params: {
                user_agent: navigator.userAgent,
                aaid: "",
                device_category: DEVICE_CATEGORY[+mobilecheck()], //TODO
                device_id: "",
                idfa: "",
                puid: puid || ""
            }
        })
    };
    
    try{
        response = await fetch(pustiPath() + "puid", options);
    }catch(err){
        let error = new Error("Failed to fetch puid");
        throw error;
    }

    if (!response.ok){
        let error = new Error(`${response.statusText}: ${response.url}`);
        error.code = `HTTP ${response.status}`;
        throw error;
    }
    
    let responseJson = await response.json();
    if (responseJson.error) {
        let error = new Error(responseJson.error.message);
        error.code = responseJson.error.code;
        throw error;
    }

    return responseJson.result;
}