import { ENV } from "./Util.js";

let instanceMap = {};

window.addEventListener("message", handleMessage);

function handleMessage(event){
    let data = event.data;
    if(typeof data !== "string") return;

    let reg = /^ppn-ex:\/\/(.+?)\:(.+)\?(.+)?/g;
    let match = reg.exec(data);
    reg.lastIndex = 0;
    if(match == null) return;
    let [,intent, ppnId, attach] = match;
    attach && (attach = parseAttach(attach));
    //let iframeWindow = event.source;
    instanceMap[ppnId] && instanceMap[ppnId].emit(intent, attach);
}

function parseAttach(attachStr) { 
    var parameters = {};
    attachStr.replace(/([^=&]+)=([^&]*)/gi, function (m, key, value) {
        parameters[key] = decodeURIComponent(value);
    });  
    return parameters;
}

export default function (ppnId, container, url){
    let instance = instanceMap[ppnId]
    if (instance != null) {
        instance.reset();
        return instance;
    }
    instance = main(ppnId, container, url);
    instanceMap[ppnId] = instance;
    return instance;
}

function main(ppnId, container){
    let eventPool = {}, target = null, reset;
    if (container == null) {
        reset = () => target = { contentWindow: window.parent };
    } else {
        let iframe =  createExIframe(ppnId)
        reset = () => target = container.appendChild(iframe);
    }
    reset();

    function postData(intent, attach = {}){
        let q = [];
        for (const key in attach) {
            if(!attach.hasOwnProperty(key)) continue;
            q.push(`${key}=${encodeURIComponent(attach[key])}`);
        }
        target.contentWindow.postMessage(`ppn-ex://${intent}:${ppnId}?${q.join("&")}`, "*"); //TODO: 指定 domain，不要用 *
    }

    function emit(intent, attach){
        let listeners = eventPool[intent];
        if(!listeners) return;
        listeners.forEach(listener => {
            listener.fn(attach);
        });
        if(listeners.once == true) {
            off(intent, listeners.fn);
        }
    }

    function on(intent, listener){
        eventPool[intent] = eventPool[intent] || [];
        eventPool[intent].push({fn:listener});
    }

    function off(intent, listener){
        let listeners = eventPool[intent];
        if(!listeners) return;
        eventPool[intent] = listeners.filter(element => element.fn !== listener );
    }

    function one(intent, listener){
        eventPool[intent] = eventPool[intent] || [];
        eventPool[intent].push({fn:listener, once: true});
    }

    return { postData, on, one, off, emit, reset };
}

function createExIframe(ppnId) {
    let iframe = document.createElement("IFRAME");
    iframe.className = "ppn-exf";
    iframe.frameBorder = "0";
    iframe.scrolling = "no";
    iframe.width = 0;
    iframe.height = 0;
    if (__RELEASE_MODE__) {
        iframe.src = `https://${ENV.current == ENV.STAGING ? "staging-" : ""}player.svc.litv.tv/v3/ex.html?id=${ppnId}`;
    } else {
        iframe.src = `${location.protocol}//${ENV.host}/ex.html?id=${ppnId}`;
    }
    return iframe;
}