import gaTracing from "./GaTracing.js";
import comscoreTracing from "./ComscoreTracing.js";
import litvTracing from "./LiTVTracing.js";
import reportTracking from "./ReportTracking.js";


export default class Tracing{
    constructor(config, meta, player, ancillary, exApi, recommend){
        return {
            report: reportTracking(config, meta, player),
            ga: gaTracing(config, meta, player, ancillary, exApi, recommend),
            comscore: comscoreTracing(config, meta, player),
            litv: litvTracing(config, player)
        };
    }
}

