import { getAssets, ENV, mobilecheck } from "../Util/Util.js";
import paraDecrypt from "../Util/ParaDecrypt.js";
import { getPuid } from "../Util/PUID/PUID.js";
import generalConfig from "/src/general_confing.json";

const PAGE_TYPE = { DIVERSION: "diversion", CONTENT: "content", HOME: "home", NONE: "none", INTENT: "intent" };
const VPAID_MOD = { 1: "ENABLE", 2: "INSECURE" };
const LiTVUrl = "https://www.litv.tv";
const MEDIA_TYPE_MAPPING = { vod: "vod", vc: "vod-channel", vcvm: "vod-channel" };
const PLAYER_MEDIA_MODE_MAPPING = { vod: "vod", vc: "simulation_live", vcvm: "vod" };

class Config {
    async init(sponsor, contentId, exApi, lepInfo) {
        //let querys = getUrlParameters(window.location.search);
        let sponsorInfo = sponsor || "";
        let [sponsorName, refSponsorName] = sponsorInfo.split("|");
        refSponsorName = refSponsorName || sponsorName;
        let partnerName = window.atob(sponsorName);
        let refPartnerName = window.atob(refSponsorName);
        let isMobile = mobilecheck();

        let config = {};
        //config.project_no = querys.p;
        config.sponsor_info = sponsorInfo;
        config.sponsor_name = sponsorName;
        config.partner_name = partnerName;
        config.ref_sponsor_name = refSponsorName;
        config.ref_partner_name = refPartnerName;
        //config.link_extra = querys.x && decodeURIComponent(querys.x);
        config.link_extra = "";
        let idparts = (contentId || "").split(".");
        if (idparts.length == 1) {
            config.play_list_id = null;
            config.preset_content_id = idparts[0];
        } else {
            config.play_list_id = idparts[0];
            config.preset_content_id = idparts[1];
        }
        //config.count_limit = parseInt(querys.o || 25);
        config.count_limit = 25;

        let diversionLink =
            LiTVUrl +
            generalConfig.diversion_page
                .replace("${ref_partner_name}", config.ref_partner_name)
                .replace("${ref_sponsor_name}", config.ref_sponsor_name);
        let contentPageLink =
            LiTVUrl + generalConfig.content_page.replace("${ref_sponsor_name}", config.ref_sponsor_name);
        config.diversion_link = diversionLink + ((config.link_extra || "") && "&" + config.link_extra);
        config.content_page_link = contentPageLink + ((config.link_extra || "") && "&" + config.link_extra);

        config.env = ENV.current;
        config.cdi_urls = generalConfig.cdi_urls;
        config.cdn_urls = generalConfig.cdn_urls;
        config.staging_cdi_urls = generalConfig.staging_cdi_urls;
        config.staging_cdn_urls = generalConfig.staging_cdn_urls;
        config.cdi_api_urls = generalConfig.cdi_api_urls;
        config.staging_cdi_api_urls = generalConfig.staging_cdi_api_urls;
        config.acs_urls = generalConfig.acs_urls;
        config.staging_pusti_urls = generalConfig.staging_pusti_urls;
        config.pusti_urls = generalConfig.pusti_urls;
        config.showcase_meta_urls = generalConfig.showcase_meta_urls.map((url) =>
            url.replace("${ref_sponsor_name}", config.ref_sponsor_name)
        );
        config.staging_showcase_meta_urls = generalConfig.staging_showcase_meta_urls.map((url) =>
            url.replace("${ref_sponsor_name}", config.ref_sponsor_name)
        );
        // is test
        //config.staging_showcase_meta_urls = generalConfig.staging_showcase_meta_urls.map( url => url.replace("${ref_sponsor_name}", "Y3Q=" ) );
        //config.dynamic_build = querys.dy == "true";
        config.dynamic_build = true;
        //config.dynamic_version = parseInt(querys.v);
        config.dynamic_version = 3;
        //config.ppn_id = querys.pid;
        config.ppn_id = "";

        let puidPromise = getPuid(exApi);

        let customConfigPromise = getAssets(`/meta/config/${partnerName}-config.json`);
        try {
            let customConfig = await customConfigPromise;
            config = Object.assign(config, paraDecrypt(customConfig.parameter));
            if (typeof customConfig.auto_play_rate != "undefined") {
                let r = Math.random() * 100;
                //console.log(r);
                config.auto_play = customConfig.auto_play_rate > r;
            } else {
                config.auto_play = config.start_play == "auto";
            }

            if (typeof customConfig.visible_threshold != "undefined") {
                // if(isMobile == true){
                //     config.visible_threshold = customConfig.visible_threshold.mobile;
                // }else{
                //     config.visible_threshold = customConfig.visible_threshold.desktop;
                // }
                config.visible_threshold = isMobile
                    ? customConfig.visible_threshold.mobile
                    : customConfig.visible_threshold.desktop;
                if (config.visible_threshold == 0.5) {
                    config.visible_threshold = 0.45;
                }
            } else {
                config.visible_threshold = 0.2;
            }

            if (typeof customConfig.floating != "undefined") {
                let blockList = customConfig.floating.block_list || [];
                let floatingOpt = customConfig.floating[isMobile ? "mobile" : "desktop"];
                config.enable_floating =
                    floatingOpt.enable && !blockList.find((pattern) => window.location.href.includes(pattern));
                config.float_pos = floatingOpt.pos;
                config.sticky_time_gap = floatingOpt.time_gap;
                config.sticky_timecode = floatingOpt.timecode;
                config.sticky_mode = floatingOpt.mode >= 0 ? floatingOpt.mode : customConfig.sticky_mode;
                config.sticky_avoid_atf = floatingOpt.avoid_atf;
                config.sticky_drag = floatingOpt.drag;
            } else {
                config.enable_floating = false;
                config.float_pos = "br";
                config.sticky_time_gap = 0;
                config.sticky_timecode = [];
                config.sticky_mode = customConfig.sticky_mode;
                config.sticky_avoid_atf = false;
                config.sticky_drag = false;
            }

            if (typeof customConfig.side_btn != "undefined" && customConfig.side_btn.url) {
                config.enable_side_btn = true;
                config.side_btn_title = customConfig.side_btn.title;
                config.side_btn_url = customConfig.side_btn.url;
            } else {
                config.enable_side_btn = false;
                config.side_btn_title = "";
                config.side_btn_url = "";
            }

            if (typeof customConfig.rebirth != "undefined") {
                config.rebirth = customConfig.rebirth;
            } else {
                config.rebirth = false;
            }

            config.check_region = customConfig.check_region;
            config.gpt_tag = customConfig.gpt_tag;
            config.get_urls = customConfig.get_urls;
            config.enable_showcase = customConfig.enable_showcase;
            //config.enable_floating = customConfig.enable_floating;
            //config.float_pos = customConfig.float_pos ? (isMobile ? customConfig.float_pos.mobile : customConfig.float_pos.desktop) : "br";
            config.ga_ids = getGaIds(customConfig.ga_ids || [], customConfig.ga_account);
            config.device_type = customConfig.device_type;
            config.clip_count = customConfig.clip_count;
            config.error_button = customConfig.error_button;
            config.heavy_ad_fill_banner_tags = customConfig.heavy_ad_fill_banner_tags;
            config.bottom_link_type = config.bottom_link_type || PAGE_TYPE.DIVERSION;
            config.inside_title_link_type = config.inside_title_link_type || PAGE_TYPE.DIVERSION;
            config.link = customConfig.link || {};
            config.playlist_ui = customConfig.playlist_ui;
            config.early_midroll = customConfig.early_midroll || {};
            // immed_and_play preload if sticky is set ON_AD_START.
            config.preload_ad =
                config.enable_floating && config.sticky_mode == 2 ? "immed_and_play" : customConfig.preload_ad || "";
            config.enable_tracking = !!customConfig.tracking;

            config.brandLogo = customConfig.brand_logo || {};
            config.logo_link = getLitvLogoLink(config);
            config.media_type = MEDIA_TYPE_MAPPING[config.type];
            config.player_media_mode = PLAYER_MEDIA_MODE_MAPPING[config.type];
            config.meta_type = getMetaType(config);
            // set vpaidmode to insecure to get mouse events in iframes to drag sticky
            config.vpaid_mode = VPAID_MOD[config.sticky_drag ? 2 : customConfig.vpaid_mode];
            config.vast_click_area = customConfig.vast_click_area || "default";
            config.allow_ad_pause = customConfig.allow_ad_pause || false;
        } catch (error) {
            config.hasFail = true;
            config.error = error;
        }

        config.puid = await puidPromise;

        this.config = config;
        let {
            cdi_urls,
            cdn_urls,
            content_page_link,
            staging_cdi_urls,
            staging_cdn_urls,
            cdi_api_urls,
            staging_cdi_api_urls,
            showcase_meta_urls,
            staging_showcase_meta_urls,
            media_type,
            player_media_mode,
            ...keep
        } = this.config;
        lepInfo = Object.assign(lepInfo, keep);
        return this;

        function getGaIds(gaIds, gaAccount) {
            if (gaAccount && gaIds.indexOf(gaAccount) < 0) {
                gaIds.push(gaAccount);
            }
            return gaIds;
        }
    }

    // get projectNo(){
    //     return this.config.project_no;
    // }

    get sponsorInfo() {
        return this.config.sponsor_info;
    }

    get sponsorName() {
        return this.config.sponsor_name;
    }

    get partnerName() {
        return this.config.partner_name;
    }

    get refSponsorName() {
        return this.config.ref_sponsor_name;
    }

    get refPartnerName() {
        return this.config.ref_partner_name;
    }

    get type() {
        return this.config.type;
    }

    get mediaType() {
        return this.config.media_type;
    }

    get playerMediaMode() {
        return this.config.player_media_mode;
    }

    get metaType() {
        return this.config.meta_type;
    }

    // get mute(){
    //     return this.config.mute;
    // }

    get autoPlay() {
        return this.config.auto_play;
    }

    get bottom() {
        return this.config.bottom;
    }

    get bottomLinkType() {
        return this.config.bottom_link_type;
    }

    get insideTitle() {
        return this.config.inside_title;
    }

    get insideTitleLinkType() {
        return this.config.inside_title_link_type;
    }

    get recommendDisplay() {
        return this.config.recommend_display;
    }

    get recommendStyle() {
        return this.config.recommend_style;
    }

    get env() {
        return this.config.env;
    }

    get presetContentId() {
        return this.config.preset_content_id;
    }

    set presetContentId(id) {
        this.config.preset_content_id = id;
    }

    get playListId() {
        return this.config.play_list_id;
    }

    set playListId(id) {
        this.config.play_list_id = id;
    }

    get cdiUrls() {
        return this.config.cdi_urls;
    }

    get cdnUrls() {
        return this.config.cdn_urls;
    }

    get stagingCdiUrls() {
        return this.config.staging_cdi_urls;
    }

    get stagingCdnUrls() {
        return this.config.staging_cdn_urls;
    }

    get cdiApiUrls() {
        return this.config.cdi_api_urls;
    }

    get stagingCdiApiUrls() {
        return this.config.staging_cdi_api_urls;
    }

    get acsUrls() {
        return this.config.acs_urls;
    }

    get stagingPustiUrls() {
        return this.config.staging_pusti_urls;
    }

    get pustiUrls() {
        return this.config.pusti_urls;
    }

    get showcaseMetaUrls() {
        return this.config.showcase_meta_urls;
    }

    get stagingShowcaseMetaUrls() {
        return this.config.staging_showcase_meta_urls;
    }

    get logoLink() {
        return this.config.logo_link;
    }

    get fontSize() {
        return this.config.font_size;
    }

    get countLimit() {
        return this.config.count_limit;
    }

    get deviceType() {
        return this.config.device_type;
    }

    get clipCount() {
        return this.config.clip_count;
    }

    get vpaidMode() {
        return this.config.vpaid_mode;
    }

    get vastClickArea() {
        return this.config.vast_click_area;
    }

    get getUrls() {
        return this.config.get_urls;
    }

    get checkRegion() {
        return this.config.check_region;
    }

    get gptTag() {
        return this.config.gpt_tag;
    }

    get enableShowcase() {
        let cfg = this.config;
        return cfg.playlist_ui == "out" || (cfg.enable_showcase && cfg.dynamic_build && cfg.dynamic_version >= 2);
    }

    get enableFloating() {
        return this.config.enable_floating;
    }

    get floatPos() {
        return this.config.float_pos;
    }

    get enableSideBtn() {
        return this.config.enable_side_btn;
    }

    get sideBtnTitle() {
        return this.config.side_btn_title;
    }

    get sideBtnUrl() {
        return this.config.side_btn_url;
    }

    get dynamicVersion() {
        return this.config.dynamic_version;
    }

    get ppnId() {
        return this.config.ppn_id;
    }

    get puid() {
        return this.config.puid;
    }

    get gaIds() {
        return this.config.ga_ids;
    }

    get logoDisplay() {
        return this.config.logo_display;
    }

    get errorButton() {
        return this.config.error_button;
    }

    get visibleThreshold() {
        return this.config.visible_threshold;
    }

    get dynamicBuild() {
        return this.config.dynamic_build;
    }

    get rebirth() {
        return this.config.rebirth;
    }

    get heavyAdFillBannerTags() {
        return this.config.heavy_ad_fill_banner_tags;
    }

    get stickyMode() {
        return this.config.sticky_mode;
    }

    get stickyTimeGap() {
        return +this.config.sticky_time_gap;
    }

    get stickyTimecode() {
        return this.config.sticky_timecode;
    }

    get stickyAvoidAtf() {
        return this.config.sticky_avoid_atf;
    }

    get stickyDrag() {
        return this.config.sticky_drag;
    }

    get playlistUi() {
        return this.config.playlist_ui;
    }

    get earlyMidroll() {
        return this.config.early_midroll;
    }

    get preloadAd() {
        return this.config.preload_ad;
    }

    get allowAdPause() {
        return this.config.allow_ad_pause;
    }

    get enableTracking() {
        return this.config.enable_tracking;
    }

    get brandLogo() {
        const { bottom_bar, inside_player, click_through } = this.config.brandLogo;
        return {
            get bottomBar() {
                return bottom_bar;
            },
            get insidePlayer() {
                return inside_player;
            },
            get clickThrough() {
                return click_through;
            },
        };
    }

    getDiversionLink(contentType, contentId, extra) {
        let link = this.config.diversion_link;
        link += (contentType || "") && "&content_type=" + contentType;
        link += (contentId || "") && "&content_id=" + contentId;
        link += (extra || "") && "&" + extra;
        return link;
    }

    getContentPageLink(contentType, contentId, extra) {
        let link = this.config.content_page_link;
        link = link.replace("${content_type}", contentType);
        link += (contentId || "") && "&content_id=" + contentId;
        link += (extra || "") && "&" + extra;
        return link;
    }

    getInsideTitleLink(contentType, contentId, extra) {
        let link;
        if (this.config.inside_title_link_type == PAGE_TYPE.DIVERSION) {
            link = this.getDiversionLink(contentType, contentId, extra);
        } else if (this.config.inside_title_link_type == PAGE_TYPE.CONTENT) {
            link = this.getContentPageLink(contentType, contentId, extra);
        } else if (this.config.inside_title_link_type == PAGE_TYPE.INTENT) {
            link = extra;
        }
        return link;
    }

    getBottomLink(contentType, contentId, extra) {
        let link;
        if (this.config.bottom_link_type == PAGE_TYPE.DIVERSION) {
            link = this.getDiversionLink(contentType, contentId, extra);
        } else if (this.config.bottom_link_type == PAGE_TYPE.CONTENT) {
            link = this.getContentPageLink(contentType, contentId, extra);
        } else if (this.config.bottom_link_type == PAGE_TYPE.INTENT) {
            link = extra;
        }
        return link;
    }
}

function getLitvLogoLink(config) {
    switch (config.logo_link_type) {
        case PAGE_TYPE.DIVERSION:
            return config.diversion_link;
        case PAGE_TYPE.NONE:
            return "";
    }
    return LiTVUrl;
}

function getMetaType(config) {
    switch (config.type) {
        case "vod":
            return config.play_list_id ? "pl" : "vod";
        case "vc":
        case "vcvm":
            return "vc";
    }
    return null;
}

export default Config;
