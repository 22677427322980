import fetch from 'unfetch';
import {ENV} from "../Util/Util.js";

const GET_URLS_NO_AUTH = "LoadService.GetURLsNoAuth";
const IN_ALLOWED_REGION = "LoadService.InAllowedRegion";
const delay = (interval) => {
    return new Promise((resolve) => {
        setTimeout(resolve, interval);
    });
};
const fetchRetry = async (url, options, n, delayTime) => {
    if(delayTime){
        await delay(delayTime);
    }
    try {
        let response = await fetch(url(), options);
        if (!response.ok){
            if (n-- > 0) return await fetchRetry(url, options, n, 100);
        }
        return response;
    } catch(err) {
        if (n-- > 0) {
            return await fetchRetry(url, options, n, 100);
        }else{
            throw err;
        }
    }
};

class Api {
    constructor({env, cdiUrls, stagingCdiUrls, cdiApiUrls, stagingCdiApiUrls, pustiUrls, stagingPustiUrls, mediaType, deviceType, refPartnerName, getUrls, puid}){
        let _cdiUrls;
        if(getUrls == "cdi"){
            if(ENV.PRODUCTION == env){
                _cdiUrls = cdiApiUrls;
            }else{
                _cdiUrls = stagingCdiApiUrls;
            }
            this.site = "ep";
        }else{ //yonder
            if(ENV.PRODUCTION == env){
                _cdiUrls = cdiUrls;
            }else{
                _cdiUrls = stagingCdiUrls;
            }
            this.site = "pc";
        }
        this.cdiUrls = _cdiUrls;
        this.mediaType = mediaType;
        this.deviceType = deviceType;
        this.projectId = refPartnerName;
        this.puid = puid;

        if(ENV.PRODUCTION !== env){
            this.pustiUrls = stagingPustiUrls;
        }else{
            this.pustiUrls = pustiUrls;
        }
    }

    async getContentUrl(assetId){
        let cdiUrls = this.cdiUrls;
        let getCdiUrls = () => cdiUrls[(Math.random() * cdiUrls.length) | 0];
        let response;
        let opt = {
            method: "POST",
            headers: {
                "Accept": "application/json, text/plain, */*",
                "Content-Type": "application/json"
            },
            body:JSON.stringify({
                id: (Math.random() * 1000) | 0,
                jsonrpc: "2.0",
                method: GET_URLS_NO_AUTH,
                params: {
                    AssetId: assetId,
                    DeviceType: this.deviceType,
                    MediaType: this.mediaType,
                    ProjectID: this.projectId,
                    Site: this.site,
                    puid: this.puid
                }
            })
        };
        try{
            response = await fetchRetry(getCdiUrls, opt, 1);
        }catch(err){
            let error = new Error("Failed to fetch Content url");
            throw error;
        }

        if (!response.ok){
            let error = new Error(`${response.statusText}: ${response.url}`);
            error.code = `HTTP ${response.status}`;
            throw error;
        }
        
        let responseJson = await response.json();
        if (responseJson.error) {
            let error = new Error(responseJson.error.message);
            error.code = responseJson.error.code;
            throw error;
        }

        let result = responseJson.result;
        return result.AssetURLs[0];
    }

    async inAllowedRegion(){
        let cdiUrls = this.cdiUrls;
        let getCdiUrls = () => cdiUrls[(Math.random() * cdiUrls.length) | 0];
        let response;
        let opt = {
            method: "POST",
            headers: {
                "Accept": "application/json, text/plain, */*",
                "Content-Type": "application/json"
            },
            body:JSON.stringify({
                id: (Math.random() * 1000) | 0,
                jsonrpc: "2.0",
                method: IN_ALLOWED_REGION,
                params: {}
            })
        };
        try{
            response = await fetchRetry(getCdiUrls, opt, 1);
        }catch(err){
            let error = new Error("Failed to fetch inAllowedRegion");
            throw error;
        }

        if (!response.ok){
            let error = new Error(`${response.statusText}: ${response.url}`);
            error.code = `HTTP ${response.status}`;
            throw error;
        }
        
        let responseJson = await response.json();
        if (responseJson.error) {
            let error = new Error(responseJson.error.message);
            error.code = responseJson.error.code;
            throw error;
        }

        return responseJson.result;
    }

    callbackPpn(para){
        fetch(this.pustiUrls + "callback/ppn", {
            method: "POST",
            headers: {
                "Accept": "application/json, text/plain, */*",
                "Content-Type": "application/json"
            },
            body:JSON.stringify({
                Host: para.host,
                Path: para.path,
                Titles: para.title,
                PUID: this.puid
            })
        });
    }
}

export default Api;