const schemeTable = {
    e: [{ name: "type", type: "Enum", map: ["vod", "vc", "vcvm"] }], //type => 0:隨選 1:整套看 player mode = live 2:整套看 player mode = vod
    //u: [{name:"mute", type:"Boolean"}],
    a: [{ name: "start_play", type: "Enum", map: ["click", "auto", "rate"] }],
    //s: [{name:"seek_bar_logo", type:"Boolean"}],
    //m: [{name:"more", type:"Boolean"}],
    //t: [{name:"top", type:"Boolean"}, {name:"top_logo", type:"Enum", map:["hide", "show", "diversion"]}], //top_logo => 0: 不顯示 logo; 1: 顯示 logo，連結為預設; 2:顯示 logo，連結為導流
    b: [
        { name: "bottom", type: "Boolean" },
        { name: "bottom_link_type", type: "Enum", map: ["diversion", "content", "none", "intent"] },
    ],
    l: [
        { name: "logo_link_type", type: "Enum", map: ["home", "diversion", "none"] },
        { name: "logo_display", type: "Boolean" },
    ],
    i: [
        { name: "inside_title", type: "Boolean" },
        { name: "inside_title_link_type", type: "Enum", map: ["diversion", "content", "none", "intent"] },
    ],
    r: [
        { name: "recommend_display", type: "Enum", map: ["hide", "show", "only_fullscreen"] },
        { name: "recommend_style", type: "Enum", map: ["default"] },
    ],
    f: [{ name: "font_size", type: "Enum", map: ["normal", "big", "tiny"] }],
};

function expand(src, scheme) {
    let result = {};
    let keys = Object.keys(scheme);
    let reg = new RegExp(`(${keys.join("|")})(\\d+)`, "gi"); // -> /(e|s|i|a|r|b|m|t|u)(\d+)/gi
    src.replace(reg, ($0, $1, $2) => {
        let _schemes = scheme[$1];
        let values = $2.split("");
        values.forEach((v, i) => {
            let _scheme = _schemes[i];
            let value;
            switch (_scheme.type) {
                case "Boolean":
                    value = v !== "0";
                    break;
                case "Enum":
                    value = parseInt(v);
                    if (_scheme.map) {
                        value = _scheme.map[value];
                    }
                    break;
                case "Number":
                    value = parseInt(v);
                    break;
                default:
                    value = v;
                    break;
            }
            //let value = _scheme.type == "Boolean" ? v !== "0" : _scheme.type == "Enum" ? parseInt(v) : v;

            result[_scheme.name] = value;
        });
    });

    return result;
}

export default function (src) {
    return expand(src, schemeTable);
}
//console.log(expand("e1u0a1s1m1t11b10i11r10", schemeTable));
