import Player from './Player.js';
import EVENT from './Events.js';
import PRELOAD_TYPE from './PreloadType.js';
import SecretiveDebug from './extension/SecretiveDebug.js';
import LogLeaveTime from './extension/LogLeaveTime.js';

const LiTVPlayerVersion = "__version";

export default class LiTVPlayer {
    constructor(){
        throw "This is a factory, you must create a new LiTVPlayer instance using the 'create' method.";
    }
    
    static create(container, options = {}){
        //TODO destroy
        let player;
        this.instanceMap = this.instanceMap || new Map();
        if(this.instanceMap.has(container)){
            player = this.instanceMap.get(container);
        }else{
            this.count = typeof this.count === "number" ? ++this.count : 0;
            let instanceId = "$" + this.count;
            player = new Player(container, options, LiTVPlayerVersion);
            this[instanceId] = player;
            this.instanceMap.set(container, player);
            SecretiveDebug.addPlayer(container, player);
            LogLeaveTime(player);
            player.on(EVENT.WILL_SHUTDOWN, () => {
                SecretiveDebug.removePlayer(container, player);
                this[instanceId] = null;
                delete this[instanceId];
            });
        }

        return player;
    }
}

LiTVPlayer.prototype.EVENT = LiTVPlayer.EVENT = EVENT;
LiTVPlayer.prototype.version = LiTVPlayer.version = LiTVPlayerVersion;
LiTVPlayer.prototype.PRELOAD_TYPE = LiTVPlayer.PRELOAD_TYPE = PRELOAD_TYPE;

LiTVPlayer.conf = {};
let confProperty = ["traceAdRequestTime", "traceLeaveTime", "traceAdDuration"];
if(Object.defineProperty){
    confProperty.forEach(key => {
        Object.defineProperty(LiTVPlayer, key, {
            set: val => LiTVPlayer.conf[key] = val,
            get: () => LiTVPlayer.conf[key]
        })
    })
}else{
    confProperty.forEach(key => {
        LiTVPlayer.__defineGetter__(key, function(){ return LiTVPlayer.conf[key]; });
        LiTVPlayer.__defineSetter__(key, function(val){ LiTVPlayer.conf[key] = val; });
    });
}

if(window.LiTVPlayer){
    if(typeof window.LiTVPlayer === "function" && typeof window.LiTVPlayer.create === "function"){
        window.LiTVPlayer = window.LiTVPlayer;
    }else{
        let temp = window.LiTVPlayer;
        window.LiTVPlayer = LiTVPlayer;
        if(typeof temp.prototype === "undefined"){
            for(let k in temp){
                if(confProperty.includes(k)) LiTVPlayer.conf[k] = temp[k];
            }
        }
    }
}else{
    window.LiTVPlayer = LiTVPlayer;
}

document.addEventListener("keydown", onKeyDown, true);
document.addEventListener("keyup", onKeyUp, true);

function onKeyDown(event) {
    const player = Player.prototype.focus;
    if (!player || !player.vjsInfo.keyboard) {
        return;
    }

    const metaKeyPressed = event.ctrlKey || event.metaKey || event.altKey;
    if (!metaKeyPressed && event.shiftKey) {
        return;
    }

    const handled = handleKey(event.which); // use event.code?
    if (handled) {
        player.vjsPlayer.userActive(true);
        event.stopImmediatePropagation();
    }

    function handleKey(key) {
        switch (key) {
            case 39: // ArrowRight
                player.seekBarMoving(10);
                break;
            case 37: // ArrowLeft
                player.seekBarMoving(-10);
                break;
            case 32: // Space
                player.paused ? player.play() : player.pause();
                break;
            case 38: // ArrowUp
                if (player.muted) {
                    player.mute(false);
                    player.volume(0.1);
                } else {
                    player.volume(player.volumeLevel + 0.1);
                }
                break;
            case 40: // ArrowDown
                if (!player.currentMuted) {
                    player.volume(player.volumeLevel - 0.1);
                }
                break;
            default:
                return false;
        }
        return true;
    }
}
function onKeyUp(event) {
    const player = Player.prototype.focus;
    if (!player || !player.vjsInfo.keyboard) {
        return;
    }

    const handled = handleKey(event.which);
    if (handled) {
        event.stopImmediatePropagation();
    }

    function handleKey(key) {
        switch (key) {
            case 39: // ArrowRight
            case 37: // ArrowLeft
                player.seekBarMoveEnd();
                break;
            default:
                return false;
        }
        return true;
    }
}