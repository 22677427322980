import { ENV_STRINGS } from './Util.js';

const defaultUrlConfig = {
    cdiProxy: {
              local: ["https://d-proxy.svc.litv.tv/cdi/v2/rpc"],
        development: ["https://d-proxy.svc.litv.tv/cdi/v2/rpc"],
            staging: ["https://s-proxy.svc.litv.tv/cdi/v2/rpc"],
         production: ["https://proxy.svc.litv.tv/cdi/v2/rpc"]
    },
    cdiApi: {
              local: ["https://d-proxy.svc.litv.tv/cdi/v2/rpc"],
        development: ["https://d-proxy.svc.litv.tv/cdi/v2/rpc"],
            staging: ["https://s-proxy.svc.litv.tv/cdi/v2/rpc"],
         production: ["https://proxy.svc.litv.tv/cdi/v2/rpc"]
    },
    adDebug: {
              local: [],
        development: ["https://mc062tqbwa.execute-api.ap-northeast-1.amazonaws.com/staging/debug"],
            staging: ["https://mc062tqbwa.execute-api.ap-northeast-1.amazonaws.com/staging/debug"],
         production: ["https://mc062tqbwa.execute-api.ap-northeast-1.amazonaws.com/production/debug"]
    },
    cdnStatic: {
              local: ["https://cdnstatic-staging.svc.litv.tv/"],
        development: ["https://cdnstatic-staging.svc.litv.tv/"],
            staging: ["https://cdnstatic-staging.svc.litv.tv/"],
         production: ["https://cdnstatic.svc.litv.tv/"]
    }
};

const fields = Object.keys(defaultUrlConfig);

function configMerge(preConfig, newConfig){
    let _config = Object.assign({}, preConfig);
    fields.forEach(field => {
        let item = newConfig[field];
        if(!item) return;
        ENV_STRINGS.forEach(env => {
            if(!item[env]) return;
            _config[field][env] = item[env];
        });
    });
    return _config;
}

function getUrl(field, urlConfig, env){
    let ls = urlConfig[field][env];
    return ls[(Math.random() * ls.length) >> 0];
}

export default class UrlConfig{
    constructor(env){
        this.urlConfig = configMerge(defaultUrlConfig, {});
        this.env = env;
    }

    get cdiProxy(){
        return getUrl("cdiProxy", this.urlConfig, this.env);
    }

    get cdiApi(){
        return getUrl("cdiApi", this.urlConfig, this.env);
    }

    get adDebug(){
        return getUrl("adDebug", this.urlConfig, this.env);
    }

    get cdnStatic(){
        return getUrl("cdnStatic", this.urlConfig, this.env);
    }

    setConfig(customConfig = {}){
        if(customConfig == null) return;
        this.urlConfig = configMerge(this.urlConfig, customConfig);
    }

    getAll(){
        return this.urlConfig;
    }

}