const GA_LABEL = {
    "VOD_IMPRESSION": "vod-impression",
    "FIRST_IMPRESSION": "first-impression",
    "FULLSCREEN": "em-fullscreen",
    //"MORE_CLICK": "em-more-click",
    "LOGO_CLICK": "em-logo-click",
    //"SERIES_CLICK": "em-series-click",
    "UNMUTE": "em-unmute",
    "FOOTER": "em-footer",
    "ERROR": "em-error",
    //"CLICK_PLAY": "em-click-play",
    //"OBEY_STOP": "playing-stop",
    //"OBEY_RESTART": "player-began"
};

function sendGaFromPlayer(meta, exApi){
    return function(label, affixation){
        let eventCategory = `${meta.contentType}|${meta.secondaryTitle}|${meta.season}|${meta.episode}|${meta.videoType}` + ((affixation || "") && "|" + affixation);
        //console.log(label, eventCategory);
        exApi.gtag('event', 'em', {
            'event_label': label, 
            'event_category': eventCategory
        });
    }
}

export default function gaTracing(config, meta, player, ancillary, exApi, recommend){
    config.gaIds.forEach(id => {
        exApi.gtag('config', id, {'send_page_view': false, 'cookie_flags': 'SameSite=None;Secure', 'user_id': config.puid});
    });
    let _sendGaFromPlayer = sendGaFromPlayer(meta, exApi);

    Array.from(document.querySelectorAll("[data-ga-tracing]")).forEach(item => {
        delete item.dataset.gaTracing;
        item.addEventListener(item.dataset.gaAction, e => _sendGaFromPlayer(item.dataset.gaLabel));
    });

    player
    .one(player.EVENT.IMPRESSION, e => _sendGaFromPlayer(GA_LABEL.FIRST_IMPRESSION))
    .one(player.EVENT.FULLSCREEN, e => _sendGaFromPlayer(GA_LABEL.FULLSCREEN))
    .on(player.EVENT.IMPRESSION, e => _sendGaFromPlayer(GA_LABEL.VOD_IMPRESSION))
    .on(player.EVENT.CLICK_LOGO, e => e.id == "*litv_logo" && _sendGaFromPlayer(GA_LABEL.LOGO_CLICK))
    //.on(player.EVENT.CLICK_CAPTION, e => _sendGaFromPlayer(GA_LABEL.SERIES_CLICK))
    .on(player.EVENT.ERROR, e => _sendGaFromPlayer(GA_LABEL.ERROR, e.error.code))
    .on(player.EVENT.MUTE, onMutedChange);

    // if(config.autoPlay == false || player.platformInfo.isIOSAndInIFrame == true){
    //     player.one(player.EVENT.CLICK, e => _sendGaFromPlayer(GA_LABEL.CLICK_PLAY));
    // }

    ancillary
    .on(ancillary.EVENT.SHOWCASE_CLICK, e => { 
        exApi.gtag("event", "click", {
            event_category: `${config.refPartnerName}_showcase`,
            event_label: `${e.page}|${e.item}|${e.title}`
        });
    })
    // .on(ancillary.EVENT.SHOWCASE_IMPRESSION, e => { //NOTE: 2021/05/31 收到移除的需求
    //     exApi.gtag("event", "impression", {
    //         event_category: `${config.refPartnerName}_showcase`
    //     });
    // })
    ;

    //recommend.one(recommend.EVENT.OPENED, e => e.conscious && _sendGaFromPlayer(GA_LABEL.MORE_CLICK));

    //obeyPlayBehavior
    //.on(obeyPlayBehavior.EVENT.STOP, e => _sendGaFromPlayer(GA_LABEL.OBEY_STOP))
    //.on(obeyPlayBehavior.EVENT.RESTART, e => _sendGaFromPlayer(GA_LABEL.OBEY_RESTART));

    function onMutedChange(e){
        if(e.muted == true) return;
        _sendGaFromPlayer(GA_LABEL.UNMUTE);
        player.off(player.EVENT.MUTE, onMutedChange);
    }

    function sendOnce(){
        let sended = [];
        return function(action, category, label){
            let para = action + category;
            if(sended.indexOf(para) > -1) return;
            sended.push(para);
            send(action, category, label);
        };
    }

    function send(action, category, label){
        let para = {event_category: category};
        if(label !== false){
            para.event_label = label || meta.secondaryTitle;
        }
        exApi.gtag("event", action, para);
    }
    
    return {
        send,
        sendOnce: sendOnce()
    }
}