import dragIcon from "../../images/drag_area_icon.svg";

export default function DragDrop(item, tracing) {
    let moveParam = null
    let isDragging = false;
    let isFirstMove = true;

    listenersInit();

    function onMouseDown(event) {
        if (isDragging) {
            return
        }
        onDragStart(event.screenX, event.screenY);
    }

    function onTouchStart(event) {
        if (isDragging) {
            return
        }
        onDragStart(event.changedTouches[0].screenX, event.changedTouches[0].screenY);
    }

    function onDragStart(startX, startY) {
        isDragging = true;
        isFirstMove = true;
        moveParam = getMoveParam(startX, startY);
    }

    function onMouseMove(event) {
        if (!isDragging) {
            return;
        }
        if (event.which == 0) {
            // iframe/ima mouse up event issue while playing linear ad
            stopDrag();
            return;
        }
        moveItem(event.screenX, event.screenY);
        sendMoveTracking();
    }

    function onTouchMove(event) {
        event.preventDefault(); // prevent scroll
        moveItem(event.changedTouches[0].screenX, event.changedTouches[0].screenY);
        sendMoveTracking();
    }

    function stopDrag() {
        isDragging = false;
    }

    function getMoveParam(startX, startY) {
        const itemRect = item.getBoundingClientRect();
        const maxX = window.innerWidth - itemRect.width;
        const maxY = window.innerHeight - itemRect.height;
        const offsetX = startX - itemRect.left;
        const offsetY = startY - itemRect.top;
        return { maxX, maxY, offsetX, offsetY };
    }

    function moveItem(newX, newY) {
        let x = clamp(newX - moveParam.offsetX, 0, moveParam.maxX);
        // 30: drag icon's height
        let y = clamp(newY - moveParam.offsetY, 30, moveParam.maxY);
        item.style.left = x + "px";
        item.style.top = y + "px";
    }

    function sendMoveTracking() {
        if (isFirstMove && tracing) {
            isFirstMove = false;
            tracing.send("sticky_move", "player");
        }
    }

    function clamp(val, min, max) {
        return Math.max(min, Math.min(max, val));
    }

    function listenersInit() {
        let anchor = appendAnchor(item);

        anchor.addEventListener('mousedown', onMouseDown);
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", stopDrag);

        anchor.addEventListener('touchstart', onTouchStart);
        anchor.addEventListener("touchmove", onTouchMove, { passive: false });
        anchor.addEventListener("touchend", stopDrag);
        document.addEventListener("visibilitychange", stopDrag);

        let observer = new MutationObserver(observeIframe);
        observer.observe(item.querySelector(".ima_container,.linear_ad_container"), { childList: true, subtree: true });
    }

    function observeIframe(mutations) {
        mutations.forEach(function(mutation) {
            mutation.addedNodes.forEach(addEventListenerOnIframe);
        });
    }

    function addEventListenerOnIframe(element) {
        let iframeWindow = element.contentWindow;
        if (iframeWindow == null) {
            return;
        }
        iframeWindow.addEventListener('mousedown', onMouseDown);
        iframeWindow.addEventListener("mousemove", onMouseMove);
        iframeWindow.addEventListener("mouseup", stopDrag);
        iframeWindow.addEventListener('touchstart', onTouchStart);
        iframeWindow.addEventListener("touchmove", onTouchMove, { passive: false });
        iframeWindow.addEventListener("touchend", stopDrag);
        iframeWindow.addEventListener("visibilitychange", stopDrag);
    }

    function appendAnchor(item) {
        const anchor = document.createElement("div");
        anchor.classList.add("ppn-drag_area");
        anchor.innerHTML = `${dragIcon}`;
        item.appendChild(anchor);
        return item;
    }
}