import { ENV } from "./Util.js";
import generalConfig from "/src/general_confing.json";

export default function reportTracking(config, meta, player) {
    let nop = () => { };
    if (!config.enableTracking) {
        return {
            impression: nop,
            end: nop
        };
    }

    const EMPTY_DATA_STRING = "na";
    const TRACKING_BASE_URL = (ENV.current == ENV.PRODUCTION ? generalConfig.report_tracking_url : generalConfig.staging_report_tracking_url) + "?";
    const defaultQueryStrings = [
        "partner_id=" + (config.partnerName || EMPTY_DATA_STRING),
        "playlist_id=" + (config.playListId || EMPTY_DATA_STRING),
        "puid=" + (config.puid || EMPTY_DATA_STRING)
    ];

    let sendTracking = queryStrings => {
        queryStrings = queryStrings.concat(defaultQueryStrings);
        (new Image()).src = TRACKING_BASE_URL + queryStrings.join("&");
    };

    let trackingImpression = () => sendTracking([
        "vod_content_id=" + (meta.contentId || EMPTY_DATA_STRING),
        "type=impression",
        "value=na"
    ]);

    let trackingEnd = () => sendTracking([
        "vod_content_id=" + (meta.contentId || EMPTY_DATA_STRING),
        "type=end",
        "value=" + Math.max(player.getAllCount().main, (player.currentTime / 1e3 | 0))
    ]);

    let onContentImpression = () => {
        onContentEnd = nop;
        if (meta.contentId) {
            trackingImpression();
            onContentEnd = onCommonContentEnd;
        }
    };

    let onCommonContentEnd = () => {
        trackingEnd();
        onContentEnd = nop;
    };

    let onContentEnd = nop;
    let onContentEndWrapper = () => onContentEnd();

    player.on(player.EVENT.IMPRESSION, onContentImpression);
    player.on(player.EVENT.FILM_FINISH, onContentEndWrapper);
    window.addEventListener("beforeunload", onContentEndWrapper);

    return {
        impression: onContentImpression,
        end: onContentEndWrapper,
    };
};