import EVENT from "./Events.js";
import qualityLevels from "./qualityLevel/plugin";
import registerQualityButton from "./UiComponent/QualityButton";
import registerAdControlBar from "./UiComponent/AdControlBar";
import registerAdMuteToggle from "./UiComponent/AdMuteToggle";
import registerAdPlayToogle from "./UiComponent/AdPlayToogle";
import registerBrand from "./UiComponent/Brand";
import registerCaption from "./UiComponent/Caption";
import registerUpperToolBar from "./UiComponent/UpperToolBar";

export default function customizeVjs(container, ctx, options, triggerEvent) {
    const isMobile = videojs.browser.IS_IOS || videojs.browser.IS_ANDROID;

    if (!videojs.getPlugin("liCustom")) {
        videojs.registerPlugin("liCustom", () => {});
        videojs.registerPlugin("qualityLevels", qualityLevels);
        registerQualityButton(videojs);
        registerAdControlBar(videojs);
        registerAdMuteToggle(videojs);
        registerAdPlayToogle(videojs);
        registerBrand(videojs);
        registerCaption(videojs);
        registerUpperToolBar(videojs);
        changeFullScreenAction(videojs, options);
        changeSeekBarAction(videojs);
        changeHlsBufferLength();
        changeTimeFormat();
    }

    let vjsElt = container.appendChild(document.createElement("video"));
    vjsElt.className = "video-js " + (isMobile ? "vjs-ui-mobile" : "vjs-ui-standard");
    vjsElt.controls = true;
    vjsElt.disablePictureInPicture = true;

    let vjsPlayer = videojs(vjsElt, {
        autoplay: false,
        playsinline: true,
        aspectRatio: "16:9",
        children: genPlayerChildren(),
    });

    vjsPlayer.handleTechTouchStart_ = onTouchStart;
    if (!isMobile) {
        const controlBar = vjsPlayer.controlBar;
        controlBar.progressControl.seekBar.playProgressBar.timeTooltip.hide();
        controlBar.on("mouseleave", () => controlBar.qualityButton.unpressButton());

        const { volumeControl, muteToggle } = controlBar;
        volumeControl.volumeBar.mouseVolumeLevelDisplay.hide();
        volumeControl.on("mouseleave", () => (muteToggle.contentEl().style.opacity = ""));
        volumeControl.on("mouseover", () => (muteToggle.contentEl().style.opacity = 1));
    }

    vjsPlayer.playWrapper = () =>
        document.body.contains(container) ? vjsPlayer.play() : Promise.reject("player is removed from doc body.");
    vjsPlayer.el_.oncontextmenu = e => e.preventDefault();

    return vjsPlayer;

    function genPlayerChildren() {
        return [
            "mediaLoader",
            { name: "component", id: "nonLinearImage", className: "nonlinear_image_container" },
            { name: "controlBar", children: genControlBarChildren() },
            {
                name: "upperToolBar",
                children: [{ name: "caption", clickHandler: () => triggerEvent(EVENT.CLICK_CAPTION) }],
            },
            { name: "adControlBar", children: getAdControlChildren() },
            { name: "component", el: genWaitingAnimEl() },
            { name: "component", id: "logo", className: "logo_container" },
            { name: "component", id: "linearImage", className: "linear_image_container linear_ad" },
            { name: "component", id: "houseVideoAd", className: "house_video_ad_container linear_ad" },
            { name: "component", id: "ima", className: "ima_container" },
            "errorDisplay",
            "resizeManager",
        ];
    }

    function getAdControlChildren() {
        const result = options.allowAdPause ? [{ name: "adPlayToogle", ctx }] : [];
        result.push({ name: "adMuteToggle", ctx });
        return result;
    }

    function genControlBarChildren() {
        const { disableFullscreen, brand } = options;
        const result = [
            "playToggle",
            "currentTimeDisplay",
            "timeDivider",
            "durationDisplay",
            {
                name: "progressControl",
                seekBar: { children: ["playProgressBar"].concat(isMobile ? [] : "mouseTimeDisplay") },
            },
            "muteToggle",
        ]
            .concat(isMobile ? [] : ["volumeControl", "qualityButton"])
            .concat(disableFullscreen ? [] : "fullscreenToggle")
            .concat(brand ? { name: "brand", brand, clickHandler: () => triggerEvent(EVENT.CLICK_BRAND) } : []);

        return result;
    }

    function genWaitingAnimEl() {
        let waiting = document.createElement("DIV");
        waiting.classList.add("vjs-waiting-anim");
        waiting.innerHTML = "<em></em><em></em><em></em>";
        return waiting;
    }
}

function changeHlsBufferLength() {
    if (!videojs.Vhs) {
        return;
    }
    videojs.Vhs.GOAL_BUFFER_LENGTH = 3;
    videojs.Vhs.MAX_GOAL_BUFFER_LENGTH = 10;
}

function changeTimeFormat() {
    videojs.setFormatTime(time => {
        if (isNaN(time) || time === Infinity) {
            return "--:--";
        }
        if (time < 1) {
            return "00:00";
        }
        let h = (time / 3600) | 0;
        let m = (time / 60) | 0;
        let s = (time - m * 60) | 0;
        m -= h * 60;

        h = h > 0 ? h + ":" : "";
        m = (m < 10 ? "0" + m : m) + ":";
        s = s < 10 ? "0" + s : s;
        return h + m + s;
    });
}

function onTouchStart(event) {
    const userWasActive = this.userActive();
    if (userWasActive || this.paused()) {
        this.handleTechClick_(event);
    }
    this.userWasActive = userWasActive;
}

function changeFullScreenAction(videojs, options) {
    if (options.useNativeFullscreen) {
        return;
    }
    const Player = videojs.getComponent("Player");
    Player.prototype.requestFullscreenHelper_ = function (fullscreenOptions) {
        this.enterFullWindow();
    };
    Player.prototype.exitFullscreenHelper_ = function () {
        this.exitFullWindow();
    };
}

function changeSeekBarAction(videojs) {
    const Slider = videojs.getComponent("Slider");
    Slider.prototype.calculateDistance = function (event) {
        const SliderBox = this.el_.getBoundingClientRect();
        const offsetX = window.pageXOffset + SliderBox.left;
        const distanceX = (event.changedTouches ? event.changedTouches[0].pageX : event.pageX) - offsetX;

        return Math.max(0, Math.min(1, distanceX / SliderBox.width));
    };

    const SeekBar = videojs.getComponent("SeekBar");
    SeekBar.prototype.getPercent = function () {
        if (this.player_.seekBarMoving) {
            return this._percentage;
        }
        const player = this.player();
        return player.currentTime() / player.duration();
    };
    SeekBar.prototype.handleMouseDown = function handleMouseDown(event) {
        if (!videojs.dom.isSingleLeftClick(event)) {
            return;
        }
        event.stopPropagation();
        this.startSeeking(this.calculateDistance(event));
        Slider.prototype.handleMouseDown.call(this, event);
    };
    SeekBar.prototype.handleMouseMove = function (event) {
        if (!videojs.dom.isSingleLeftClick(event)) {
            return;
        }
        this.setPercentage(this.calculateDistance(event));
    };
    SeekBar.prototype.handleMouseUp = function (event) {
        if (event) {
            event.preventDefault(); // cancel mouseup for touchend event;
            event.stopPropagation();
        }
        Slider.prototype.handleMouseUp.call(this, event);
        this.stopSeeking(this.calculateDistance(event));
    };
    SeekBar.prototype.startSeeking = function (newPercentage) {
        if (this.checkSeekComplete) {
            this.player_.off("timeupdate", this.checkSeekComplete);
            this.checkSeekComplete = null;
        }
        this.player_.seekBarMoving = true;
        this.setPercentage(newPercentage);
    };
    SeekBar.prototype.stopSeeking = function (newPercentage) {
        if (newPercentage) {
            this.setPercentage(newPercentage);
        }
        const player = this.player();
        const newTime = percentageToTime(this._percentage, player.duration());
        /* 
            workaround to fix progress bar jump issue.
            seems that IOS might not update currentTime instantly on seeked event.
        */
        if (this.checkSeekComplete) {
            player.off("timeupdate", this.checkSeekComplete);
        }
        this.checkSeekComplete = () => {
            if (Math.abs(player.currentTime() - newTime) > 1) {
                return;
            }
            player.off("timeupdate", this.checkSeekComplete);
            this.checkSeekComplete = null;
            this.onSeekComplete();
        };
        player.on("timeupdate", this.checkSeekComplete);
        player.currentTime(newTime);

        function percentageToTime(percentage, duration) {
            const time = Math.floor(percentage * duration * 1000) / 1000;
            return Math.min(time, duration - 1);
        }
    };
    SeekBar.prototype.onSeekComplete = function () {
        this.player_.seekBarMoving = false;
        this.player_.trigger({ type: "timeupdate", target: this, manuallyTriggered: true });
    };
    SeekBar.prototype.setPercentage = function (newPercentage) {
        this._percentage = newPercentage;
        this.update();
        this.player_.controlBar.currentTimeDisplay.updateTextNode_(newPercentage * this.player_.duration());
    };

    const CurrentTimeDisplay = videojs.getComponent("CurrentTimeDisplay");
    CurrentTimeDisplay.prototype.updateContent = function (event) {
        if (this.player_.seekBarMoving) {
            return;
        }
        let time;
        if (this.player_.ended()) {
            time = this.player_.duration();
        } else {
            time = this.player_.scrubbing() ? this.player_.getCache().currentTime : this.player_.currentTime();
        }
        this.updateTextNode_(time);
    };
}
