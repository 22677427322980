import closeBtnSvg from "../../images/ppnio_close_btn.svg";
import LiTVPlayer from "./LiTVPlayer.js";
import Config from "../Model/Config.js";
import Meta from "../Model/Meta.js";
import Ancillary from "./Ancillary.js";
import RecommendComponent from "./Recommend.js";
import ContentManager from "./ContentManager.js";
import ErrorComponent from "./Error.js";
import GptHosting from "./GptHosting.js";
import ObeyPlayBehavior from "../Util/ObeyPlayBehavior.js";
import Api from "../Util/API.js";
import Tracing from "../Util/Tracing.js";
import StickyControl from "./StickyControl.js";
import EarlyMidrollSetup from "./EarlyMidrollSetup.js";
import { ElementQueries } from "css-element-queries";
import ExApi from "../Util/ExApi.js";
import { ENV, convertBoolString } from "../Util/Util.js";
import litvLogoWhite from "../../css/assets/litv_logo_white.png";

const fabric = `
<div class="ppn-content_wrapper">
    <div class="ppn-content">
        <div class="ppn-outer_player">
            <div class="ppn-wrapper_player ppn-ratio-16-9">
                <div class="ppn-player_main"></div>
            </div>
        </div>
        <div class="ppn-sidebar ppn-bottom">
            <a target="_blank" class="ppn-title" data-ga-tracing data-ga-action="click" data-ga-label="em-footer"></a>
            <a target="_blank" class="ppn-logo" data-ga-tracing data-ga-action="click" data-ga-label="em-logo-click"><img></a>
        </div>
        <div class="ppn-outer_ancillary">
            <div class="ppn-ancillary">
                <div class="ppn-lds-columnar"><div></div><div></div><div></div></div>
            </div>
        </div>
    </div>
    <div class="ppn-pup_close_btn hide"><div>${closeBtnSvg}</div></div>
</div>
<div class="ppn-placeholder"></div>
`;

export default class MainView {
    constructor() {
        throw "This is a factory.";
    }

    static async create(ppnId, container, sponsor, contentId, lepInfo, exposeApi) {
        let $container,
            $content,
            $wrapperPlayer,
            $playerMain,
            $title,
            $logo,
            $ancillary,
            $pupCloseBtn,
            $placeholder,
            $exIframe;
        let config,
            meta,
            api,
            player,
            ancillary,
            errorComponent,
            recommend,
            obeyPlayBehavior,
            contentManager,
            tracing,
            inAd = false;
        //interObsHosting = false

        $container = await createView(container);
        ({ $content, $playerMain, $wrapperPlayer, $title, $logo, $ancillary, $pupCloseBtn, $placeholder } =
            getUI($container));

        let exApi = ExApi(ppnId, $container, { title: document.title, domain: document.domain });
        config = await new Config().init(sponsor, contentId, exApi, lepInfo);
        meta = new Meta(config);
        api = new Api(config);

        errorComponent = new ErrorComponent($wrapperPlayer);
        errorComponent.setInfo(config, meta);

        let layoutSuccess = await layout(
            $container,
            $content,
            $logo,
            config,
            errorComponent,
            checkRegion(api, config.checkRegion)
        );
        if (layoutSuccess == false) return;

        player = createPlayer($playerMain, config, convertBoolString(container.dataset.allowFullscreen));
        // >>> workaround
        // could be removed after player update
        (function mobileCaptionClickWorkaround() {
            try {
                const caption = player.vjsPlayer.upperToolBar.caption;
                caption.on("tap", () => window.open(caption.el_.href));
            } catch (e) {}
        })();
        // <<< workaround
        ancillary = Ancillary($ancillary, player, config, meta);
        recommend = new RecommendComponent(config.recommendDisplay, player, meta);
        obeyPlayBehavior = new ObeyPlayBehavior($playerMain, config);
        contentManager = new ContentManager(
            meta,
            config,
            api,
            player,
            recommend,
            obeyPlayBehavior,
            errorComponent,
            $content,
            $title
        );
        tracing = new Tracing(config, meta, player, ancillary, exApi, recommend);

        playerEvents(player, contentManager, errorComponent, cutOut, cutIn, $container, $content);
        EarlyMidrollSetup(player, config.earlyMidroll, meta);

        errorComponent.on(errorComponent.EVENT.OCCURRED_ERROR, (e) => {
            $playerMain.classList.add("ppn-display-none");
            player && player.stop();
            ancillary.showcaseEnable();
        });

        contentManager.play(exposeApi.play);

        //XXX
        if (config.enableSideBtn == true) {
            let $sideBtn = document.createElement("a");
            $sideBtn.href = config.sideBtnUrl;
            $sideBtn.target = "_blank";
            $sideBtn.classList.add("ppn-side_btn");
            $sideBtn.innerHTML = `<div>${config.sideBtnTitle}</div>`;
            player.appendChild($sideBtn);
            $sideBtn.addEventListener("click", (e) => {
                tracing.ga.send("click", "player_episode_btn");
            });
        }

        tracing.ga.sendOnce("request", "player", false);
        tracing.litv.pageInit();
        observeDisplayView($content, tracing);
        StickyControl($container, $content, $pupCloseBtn, $placeholder, player, config, tracing);
        postHostInfo(api);
        setupExposeApi(ppnId, config, contentManager, player, errorComponent, exposeApi);

        if (config.playlistUi == "in") {
            recommend.on(recommend.EVENT.CLICK_ITEM, function (e) {
                tracing.report.end();
                contentManager.play({ id: e.contentId });
            });
        } else if (config.playlistUi == "out") {
            ancillary.on(ancillary.EVENT.SHOWCASE_PLAY_REQUEST, function (e) {
                tracing.report.end();
                contentManager.play({ id: e.contentId });
            });
        }

        function cutOut() {
            inAd = true;
            $content.classList.add("ppn-cut-out");
        }

        function cutIn() {
            inAd = false;
            $content.classList.remove("ppn-cut-out");
        }
    }
}

function createView(container) {
    container.classList.add("ppn-container");
    container.classList.add("ppn-display-none");
    container.innerHTML = fabric;

    return new Promise((resolve) => {
        let id = null;
        if (!onResolveCheck()) {
            id = setInterval(onResolveCheck, 500);
        }

        function onResolveCheck() {
            if (!isStyleLoaded()) {
                return false;
            }
            if (id) {
                clearInterval(id);
            }
            ElementQueries.init();
            resolve(container);
            return true;
        }
    });

    function isStyleLoaded() {
        return window.getComputedStyle(container).display == "none";
    }
}

function getElement(root, selector) {
    return root.querySelector(selector);
}

function getUI($container) {
    return {
        $content: getElement($container, ".ppn-content"),
        $playerMain: getElement($container, ".ppn-player_main"),
        $wrapperPlayer: getElement($container, ".ppn-wrapper_player"),
        $title: getElement($container, ".ppn-title"),
        $logo: getElement($container, ".ppn-logo"),
        $ancillary: getElement($container, ".ppn-ancillary"),
        $pupCloseBtn: getElement($container, ".ppn-pup_close_btn"),
        $placeholder: getElement($container, ".ppn-placeholder"),
    };
}

async function layout($container, $content, $logo, config, errorComponent, checkRegion) {
    if (config.enableShowcase) {
        $content.classList.add("ppn-has_ancillary");
    }
    if (config.hasFail == true) {
        errorComponent.resolve(config.error, ErrorComponent.ERROR_SOURCE.CONFIG);
    }
    if (config.enableFloating == true) {
        $container.classList.add("ppn-floating_enable");
        $container.classList.add("ppn-" + config.floatPos);
    }

    let inAllowedRegion = await checkRegion();
    if (inAllowedRegion == false) {
        GptHosting(config, $container);
        $content.classList.add("ppn-display-none");
        return false;
    }

    if (config.brandLogo.bottomBar) {
        setupLogo(config.brandLogo.bottomBar, config.brandLogo.clickThrough);
    } else if (config.logoDisplay == true) {
        setupLogo(litvLogoWhite, config.logoLink || "");
    } else {
        setupLogo("", "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
    }

    if (config.bottom == true) $content.classList.add("ppn-has_bottom");
    config.fontSize && $content.classList.add("ppn-font-" + config.fontSize);

    $container.classList.remove("ppn-display-none");
    return true;

    function setupLogo(image, href) {
        const imageEl = $logo.querySelector("img");
        if (image) {
            imageEl.src = image;
        } else {
            imageEl.style.display = "none";
        }
        $logo.href = href;
    }
}

function checkRegion(api, checkRegion) {
    return async function () {
        if (checkRegion == true) {
            let inAllowedRegion = true;
            try {
                inAllowedRegion = await api.inAllowedRegion();
            } catch (error) {}

            return inAllowedRegion;
        } else {
            return true;
        }
    };
}

function createPlayer($playerMain, config, allowFullscreen) {
    return LiTVPlayer.create($playerMain, {
        decideVisible: true,
        allowAdPause: config.allowAdPause,
        preloadType: config.preloadAd || LiTVPlayer.PRELOAD_TYPE.THRESHOLD,
        //interObsHosting: interObsHosting,
        preloadThreshold: config.visibleThreshold || 0.2,
        visibleThreshold: 0.5,
        vpaidMode: config.vpaidMode,
        vastClickArea: config.vastClickArea,
        displayClickToUnMute: true,
        disableFullscreen: allowFullscreen == null ? isDisableFullscreen(config) : !allowFullscreen,
        customApiUrlConfig: {
            cdiApi: {
                development: [],
                staging: config.stagingCdiUrls,
                production: config.cdiUrls,
            },
        },
        env: ENV.current,
    });

    function isDisableFullscreen(config) {
        if (window.ppnFullScreen && window.ppnFullScreen[config.partnerName]) {
            return false;
        }
        if (!videojs || !videojs.browser) {
            return false;
        }
        return videojs.browser.IS_IOS || videojs.browser.IS_ANDROID;
    }
}

function playerEvents(player, contentManager, errorComponent, cutOut, cutIn, $container, $content, data) {
    player.on(LiTVPlayer.EVENT.FILM_FINISH, (e) => contentManager.refresh());
    player.on(LiTVPlayer.EVENT.ENDED, contentManager.playNext);
    player.on(LiTVPlayer.EVENT.ERROR, (e) => {
        if (e.error.code == "3") {
            contentManager.play();
        } else {
            errorComponent.resolve(e.error, ErrorComponent.ERROR_SOURCE.PLAYER);
        }
    });
    player.on(LiTVPlayer.EVENT.MUTE, (e) => {
        contentManager.setMuted(e.muted);
    });
    player.on(LiTVPlayer.EVENT.LINEAR_AD_MEDIA_START, cutOut);
    player.on(LiTVPlayer.EVENT.LINEAR_AD_MEDIA_COMPLETE, cutIn);
    player.on(LiTVPlayer.EVENT.AD_ERROR, cutIn);
    player.on(LiTVPlayer.EVENT.FULLSCREEN, (e) => $content.classList.add("ppn-fullscreen"));
    player.on(LiTVPlayer.EVENT.FULLSCREEN_EXIT, (e) => $content.classList.remove("ppn-fullscreen"));
}

function observeDisplayView(target, tracing) {
    let displayViewOb = new IntersectionObserver(
        (entries) => {
            let intersectionRatio = entries[0].intersectionRatio;
            if (intersectionRatio > 0.1) {
                displayViewOb.disconnect();
                tracing.ga.sendOnce("impression", "player", false);
                tracing.litv.pageImpression();
            }
        },
        {
            threshold: 0.1,
        }
    );
    displayViewOb.POLL_INTERVAL = 100;
    displayViewOb.observe(target);

    window.addEventListener("unload", () => displayViewOb.disconnect());
}

function postHostInfo(api) {
    let hostUrl = window.ppnifRef || window.location;
    api.callbackPpn({
        host: encodeURIComponent(hostUrl.hostname),
        path: encodeURIComponent(hostUrl.pathname + hostUrl.search),
        title: encodeURIComponent(document.title),
    });
}

function setupExposeApi(ppnId, config, contentManager, player, errorComponent, exposeApi) {
    const isPlayIdSettable = config.metaType == "vod" || config.metaType == "pl";
    let _listener = exposeApi.listener;

    Object.defineProperties(exposeApi, {
        listener: {
            set: (cb) => (_listener = cb),
            configurable: true,
        },
        playId: {
            set: isPlayIdSettable ? (id) => contentManager.play({ id }) : () => {},
            configurable: true,
        },
        play: {
            set: isPlayIdSettable ? (contentInfo) => contentManager.play(contentInfo) : () => {},
            configurable: true,
        }, // { id, startTime }
    });

    let toListener = (e) => {
        if (typeof _listener !== "function") {
            return;
        }
        if (contentManager.isErrorMode) {
            // trigger error event while errorVideo is playing.
            if (e.type == "impression") _listener.call(null, { id: ppnId, type: "error", source: "meta" });
            return;
        }
        _listener.call(null, { id: ppnId, type: e.type });
    };
    let onerror = (e, source) => {
        if (typeof _listener == "function") {
            _listener.call(null, { id: ppnId, type: "error", source: source });
        }
    };
    player.on(LiTVPlayer.EVENT.IMPRESSION, toListener);
    player.on(LiTVPlayer.EVENT.RESUME, toListener);
    player.on(LiTVPlayer.EVENT.PAUSE, toListener);
    player.on(LiTVPlayer.EVENT.ENDED, toListener);
    errorComponent.on(errorComponent.EVENT.OCCURRED_ERROR, onerror);
}
