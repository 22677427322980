// import Hls from "hls.js";
// import flowplayer from "/lib/flowplayer-7_2_7/flowplayer.js"
// import LiTVPlayer from "/lib/litvplayer/LiTVPlayer.es.min.js";

// window.Hls = Hls;
// window.flowplayer = flowplayer;

// export default LiTVPlayer;

import LiTVPlayer from "/lib/litvplayerv2/LiTVPlayer.es.min.js";
export default LiTVPlayer;