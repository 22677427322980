import {ENV} from "../Util.js";
import generalConfig from "/src/general_confing.json";

export default function pustiPath(){
    let pustiUrls;
    if(ENV.current !== ENV.PRODUCTION){
        pustiUrls = generalConfig.staging_pusti_urls;
    }else{
        pustiUrls = generalConfig.pusti_urls;
    }

    return pustiUrls[(Math.random() * pustiUrls.length) | 0];
}