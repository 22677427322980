import EVENT from './Events.js';
import PLAY_ICON from '../images/play_icon.svg';
import { conditionClass, SVGCope } from './Util.js';

export default function setUpView(container, vjsPlayer, cxt, triggerEvent, prototype, platformInfo, noVolume, brand) {

    container.classList.add("litv-player");

    let playerContainer = vjsPlayer.el();

    let playIconSvgCope = new SVGCope(PLAY_ICON);
    let playIcon = playIconSvgCope.getSVGElement();
    playIcon.setAttribute("class", "vjs-play-rounded-fill");
    container.appendChild(playIcon);

    container.addEventListener("click", (e) => {
        prototype.focus = cxt;

        if (e.isTrusted == false) {
            return;
        }

        let ec = triggerEvent(EVENT.CLICK, e);
        if (ec.defaultPrevented) {
            e.stopImmediatePropagation();
        } else if (cxt._src == null) {
            e.stopImmediatePropagation();
        }
    });

    if (!prototype.focus) {
        prototype.focus = cxt;
    }

    //== 全螢幕處理 ==
    let metaViewportHandle = (() => {
        const targeViewport = "viewport-fit=cover";
        let oriViewportMetaContent = null;
        let isChange = false;

        function addCover() {
            if (isChange) return;
            oriViewportMetaContent = null;
            let viewportMeta = document.querySelector("meta[name=viewport]");
            if (viewportMeta) {
                // 已存在 viewportMeta
                let viewportMetaContent = viewportMeta.getAttribute("content");
                if (viewportMetaContent) {
                    // 已存在 viewportMeta content
                    oriViewportMetaContent = viewportMetaContent;
                    let viewportFit = viewportMetaContent.split(",").map(i => i.trim()).filter(i => i.indexOf("viewport-fit") == 0)[0];
                    if (viewportFit) {
                        // 已存在 viewport fit => 替換
                        viewportMetaContent.replace(/viewport-fit=.+?\b/, targeViewport);
                    } else {
                        // 不存在 viewport fit => 直接指定
                        if (viewportMetaContent.length > 0) viewportMetaContent += ", ";
                        viewportMetaContent += targeViewport;
                    }
                } else {
                    // 不存在 viewportMeta content => 建立 content
                    oriViewportMetaContent = "";
                    viewportMetaContent = targeViewport;
                }
                viewportMeta.setAttribute("content", viewportMetaContent);
            } else {
                // 不存在 viewportMeta => 建立 viewportMeta
                viewportMeta = document.createElement('META');
                viewportMeta.name = "viewport"
                viewportMeta.content = targeViewport;
                document.querySelector('head').appendChild(viewportMeta);
            }
            isChange = true;
            forceUpdaeViewport();
        }

        function reset() {
            if (!isChange) return;
            let viewportMeta = document.querySelector("meta[name=viewport]");
            if (!viewportMeta) return;
            if (oriViewportMetaContent == null) {
                document.querySelector('head').removeChild(viewportMeta);
            } else {
                viewportMeta.setAttribute("content", oriViewportMetaContent);
            }
            isChange = false;
            forceUpdaeViewport();
        }

        function forceUpdaeViewport() {
            let ori = document.body.scrollTop;
            document.body.scrollTop += 1;
            setTimeout(() => {
                document.body.scrollTop = ori;
            }, 1);
        }

        return {
            addCover: addCover,
            reset: reset
        };
    })();

    let preViewH = 0,
        preViewW = 0;
    let rafId = null;

    const logoContainer = vjsPlayer.getChildById('logo').el()
    function calcLogoContainerSize() {
        const playerW = 16;
        const playerH = 9;
        const playerRatio = 16 / 9;
        const viewW = playerContainer.offsetWidth;
        const viewH = playerContainer.offsetHeight;
        if (preViewW == viewW && preViewH == viewH) {
            rafId = window.requestAnimationFrame(calcLogoContainerSize);
            return;
        }
        preViewW = viewW;
        preViewH = viewH;

        const viewRatio = viewW / viewH;
        if (viewRatio >= playerRatio) {
            container.classList.remove("straight");
            container.classList.add("horizontal");
            logoContainer.style.width = viewH * playerRatio + "px";
            logoContainer.style.height = viewH + "px";
            logoContainer.style.top = "0";
            logoContainer.style.left = "50%";
            logoContainer.style.transform = "translateX(-50%)";
        } else {
            container.classList.remove("horizontal");
            container.classList.add("straight");
            logoContainer.style.width = viewW + "px";
            logoContainer.style.height = viewW * (playerH / playerW) + "px";
            logoContainer.style.top = "50%";
            logoContainer.style.left = "0";
            logoContainer.style.transform = "translateY(-50%)";
        }
        rafId = window.requestAnimationFrame(calcLogoContainerSize);
    }

    let onFullScreen = () => {
        container.classList.add("is-fullscreen");
        metaViewportHandle.addCover();
        calcLogoContainerSize();
    };

    let onExitFullScreen = () => {
        container.classList.remove("is-fullscreen");
        container.classList.remove("straight");
        container.classList.remove("horizontal");
        logoContainer.removeAttribute("style");
        metaViewportHandle.reset();
        window.cancelAnimationFrame(rafId);
        preViewH = preViewW = 0;
    };

    if (vjsPlayer.isFullscreen() == true) {
        onFullScreen();
    }

    vjsPlayer.on("fullscreenchange", () => document.fullscreenElement ? onFullScreen() : onExitFullScreen());
    vjsPlayer.on("enterFullWindow", onFullScreen);
    vjsPlayer.on("exitFullWindow", onExitFullScreen);

    function appendChild(dom) {
        return playerContainer.appendChild(dom);
    }

    resizeHandle(container, playerContainer, vjsPlayer);

    return {
        playerContainer: playerContainer,
        logoContainer: logoContainer,
        nonlinearImageContainer: vjsPlayer.getChildById('nonLinearImage').el(),
        linearImageContainer: vjsPlayer.getChildById('linearImage').el(),
        houseVideoAdContainer: vjsPlayer.getChildById('houseVideoAd').el(),
        imaAdContainer: vjsPlayer.getChildById('ima').el(),
        playIcon: playIcon,
        MainViewOperator: {
            appendChild: appendChild
        }
    };
}

function resizeHandle(container, playerContainer, vjsPlayer) {
    vjsPlayer.on("playerresize", onResize);
    onResize();
    function onResize() {
        let width = vjsPlayer.currentWidth();
        let height = vjsPlayer.currentHeight();
        container.style.setProperty("--li-player-height", height)
        conditionClass(container, "less-630", width < 630);
        conditionClass(container, "is-small", width < 600 && width >= 400);
        conditionClass(container, "is-tiny", width < 400);
        conditionClass(container, "less-300", width < 300);
        conditionClass(container, "less-200", width < 200);
    }
}