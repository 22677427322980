import fetchPuidApi from "./FetchPuidApi.js";

export async function getPuid(exApi) {
    try {
        let { puid, expired } = getLocalPuidInfo();
        if (puid == null) {
            puid = getPuidByCookie();
        }
        if (puid == null) {
            puid = await exApi.getPuid();
        }
        if (expired) {
            puid = await fetchPuid(puid);
        }
        exApi.setPuid({ puid, isNew: expired });
        return puid;
    }
    catch (e) {
        return "";
    }
}

export async function fetchPuid(_puid) {
    let { puid } = await fetchPuidApi(_puid);
    setPuidCookie(puid);
    setLocalPuid(puid);
    return puid;
}

export function getLocalPuidInfo() {
    const errorInfo = { expired: true };
    try {
        let { puid, expire } = JSON.parse(localStorage.getItem("L_PUID"));
        return puid == null ? errorInfo : { puid, expired: +new Date() > expire };
    }
    catch (e) {
        return errorInfo;
    }
}

export function setLocalPuid(puid) {
    try {
        localStorage.setItem("L_PUID", JSON.stringify({
            puid,
            expire: +new Date() + 72E5 //兩小時
        }));
    }
    catch (error) {
    }
}

export function getPuidByCookie() {
    return document.cookie.match('(^|;)\\s*L_PUID\\s*=\\s*([^;]+)')?.pop() || null;
}

export function setPuidCookie(puid) {
    let expireTime = new Date(Date.now() + 6048E5); // 七天
    document.cookie = 'L_PUID=' + puid + ';expires=' + expireTime.toGMTString();
}