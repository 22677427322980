// comScore VMX streaming tag para sample,
// para = {
//     ns_st_st: "Hulu", 			// Station Title
//     ns_st_pu: "ABC", 			// Publisher Brand Name
//     ns_st_pr: "Modern Family", 	// Program Title
//     ns_st_ep: "Do Not Push", 	// Episode Title
//     ns_st_sn: "6", 				// Episode Season Number
//     ns_st_en: "2", 				// Episode Number
//     ns_st_ge: "Comedy", 		// Content Genre
//     ns_st_ti: "*null", 			// TMS ID (unavailable)
//     ns_st_ia: "0", 				// Identical Ad Assignment Flag
//     ns_st_ce: "1", 				// Complete Episode Flag
//     ns_st_ddt: "2014-10-03", 	// Digital Air Date
//     ns_st_tdt: "2014-10-01", 	// TV Air Date
//     ns_st_ci: "6352-2fe83a", 	// Content Asset ID
//     ns_st_cl: "1260000", 		// Clip Length
//     c3: "MyChannel", 			// Dictionary Classification Value
//     c4: "*null", 				// Unused Dictionary Classification Value
//     c6: "*null" 				// Unused Dictionary Classification Value
// };

import { mobilecheck } from "./Util.js";
import ns_ from "/lib/comScore/streamsense.5.0.2.160107.min.js";
window.ns_ = ns_;

const COM_SCORE_CLIENT_ID = "18986219";
const genComscoreMmxAdNetTag = mobilecheck() ?
    () => `https://sb.scorecardresearch.com/p?c1=8&c2=${COM_SCORE_CLIENT_ID}&c3=1&c4=${encodeURIComponent(location.href)}&ns_ap_it=b&rn=${Date.now()}` :
    () => `https://sb.scorecardresearch.com/p?c1=8&c2=${COM_SCORE_CLIENT_ID}&c3=1&cj=1&c4=${encodeURIComponent(location.href)}&rn=${Date.now()}`;
/* comScore mmx*/
/* distributed content*/
window._comscore = window._comscore || [];
_comscore.push({ c1: "7", c2: COM_SCORE_CLIENT_ID ,c3: "100001" });
// _comscore.push({ c1: "8", c2: COM_SCORE_CLIENT_ID, c3: "1"});

(function() {
    var s = document.createElement("script"), el = document.getElementsByTagName("script")[0]; s.async = true;
    s.src = (document.location.protocol == "https:" ? "https://sb" : "http://b") + ".scorecardresearch.com/beacon.js";
    el.parentNode.insertBefore(s, el);
})();

const VMX_TYPES = {
    "prerolls": "LinearOnDemandPreRoll",
    "jingle": "LinearOnDemandPreRoll",
    "midrolls": "LinearOnDemandMidRoll",
    "postrolls": "LinearOnDemandPostRoll",
    "live": "LinearLive",
    "other": "Other"
};

const LiTV = "LiTV";
const NULL = "*null";
const STABLE_PARA = {
    ns_st_pu: LiTV,
    ns_st_ge: NULL, //genre
    ns_st_ddt: NULL, //digitalAirDate
    ns_st_tdt: NULL, //tvAirDate
    ns_st_ia: NULL, //adFlag
    ns_st_ce: NULL, //completeEpisode
    c4: NULL,
    c6: NULL
};

function buildUpPara(config, meta, duration){
    let para = {
        ns_st_st: `ppn-${config.refPartnerName}`,
        ns_st_pr: meta.title,
        ns_st_ep: meta.secondaryTitle,
        ns_st_sn: meta.season,
        ns_st_en: meta.episode,
        ns_st_ti: meta.assetsId, //tmsId
        ns_st_ci: meta.assetsId,
        ns_st_cl: duration.toString(),
        c3: meta.contentType,
    };
    return Object.assign(para, STABLE_PARA);
}

export default function comscoreTracing(config, meta, player){
    //TODO 疑問：
    // 4. c1 是什麼？ 1 還是 2 ?

    let streamingTag;
    player
    .on(player.EVENT.LOAD, refreshStreamingTag)
    .on(player.EVENT.LINEAR_AD_MEDIA_START, sendAdStart)
    .on(player.EVENT.LINEAR_AD_MEDIA_COMPLETE, sendStop)
    .on(player.EVENT.IMPRESSION, sendContentStart)
    .on(player.EVENT.RESUM_FOR_AD, sendContentStart)
    .on(player.EVENT.PAUSE_FOR_AD, sendStop);
    // .on(player.EVENT.AD_REQUEST, sendPublisherTag);

    sendPublisherTag();

    function refreshStreamingTag(){
        streamingTag = new ns_.StreamingTag({customerC2: COM_SCORE_CLIENT_ID});
    }

    function sendPublisherTag(){
        (new Image()).src = genComscoreMmxAdNetTag();
    }

    function sendContentStart(e){
        let duration = player.duration;
        let contentType = duration > 600000 ? "LongFormOnDemand" : "ShortFormOnDemand";
        //console.log("Content start", buildUpPara(config, meta, duration), contentType);
        streamingTag.playVideoContentPart(buildUpPara(config, meta, duration), ns_.StreamingTag.ContentType[contentType]);
    }

    function sendAdStart(e){
        // sendPublisherTag();
        let vmxAdType;
        let rollType = e.trunkType;
        if(rollType in VMX_TYPES){
            vmxAdType = VMX_TYPES[rollType]
        }else{
            vmxAdType = VMX_TYPES.other;
        }
        //console.log("linearAd Start", ns_.StreamingTag.AdType[vmxAdType], {ns_st_cl: e.duration});
        //TODO
        streamingTag.playVideoAdvertisement({ns_st_cl: e.duration}, ns_.StreamingTag.AdType[vmxAdType]);
    }

    function sendStop(){
        //console.log("linearAd Stop", "streamingTag.stop()");
        //TODO
        streamingTag && streamingTag.stop();
    }
}