export default function(videojs) {
    const Component = videojs.getComponent("Component");
    const ClickableComponent = videojs.getComponent("ClickableComponent");

    class Caption extends ClickableComponent {
        constructor(player, options = {}) {
            super(player, options);
        }
        createEl() {
            const el = videojs.dom.createEl("a", { className: "vjs-icon-placeholder caption" });
            this.textEl = el.appendChild(videojs.dom.createEl("span"));
            return el;
        }
        update({ text, link }) {
            if (text) {
                this.setText(text)
                link ? this.setLink(link) : this.removeLink();
            } else {
                this.removeText();
                this.removeLink();
            }
        }
        setText(text) {
            this.removeClass('hide');
            this.textEl.innerText = text;
        }
        removeText() {
            this.addClass('hide');
            this.textEl.innerText = "";
        }
        setLink(link) {
            this.addClass('link');
            this.setAttribute("href", link);
            this.setAttribute("target", "_blank");
        }
        removeLink() {
            this.removeClass('link');
            this.setAttribute("href", "javascript:void(0);");
            this.setAttribute("target", "_self");
        }
    }

    Component.registerComponent("Caption", Caption);
}