import "../css/main.less";
import MainView from "./Component/MainView.js";
import { mobilecheck, noop } from "./Util/Util.js";
import { loadIMA, loadVideoJs, loadPPNCustomScript } from "/lib/util/loadScript.js";

//const STAGING = "staging";
//let env;
window.lepInfo = {
    version: VERSION,
    sw_update_date: UPDATE_DATE,
};

async function init() {
    window.ppnApi = window.ppnApi || {};
    let ppnioTmp = [];
    let recordId = (id) => {
        ppnioTmp.push(id);
        window.ppnApi[id] = window.ppnApi[id] || {};
    };
    if (Object.prototype.toString.call(window.ppnio) === "[object Array]") {
        window.ppnio.forEach(recordId);
    } else {
        window.ppnio = { push: recordId };
    }

    await Promise.all([loadIMA().catch(noop), loadVideoJs()]);
    //env = establishEnv();
    if (ppnioTmp.length > 0) {
        ppnioTmp.forEach(viewInit);
    }

    window.ppnio = {
        push: function () {
            [...arguments].forEach(viewInit);
        },
    };
}

// function establishEnv() {
//     let jssrc = document.querySelector("script[src*='ppnio.js']").src;
//     return /staging-/gi.test(jssrc) ? STAGING + "-" : "";
// }

function viewInit(item) {
    if (typeof item == "string") {
        initById(item);
    } else if (item instanceof HTMLElement) {
        initByDomElement(item);
    }
}

function initById(id) {
    let container = document.getElementById(id);
    let cinfo = id.split("-")[1].split(":");
    let sponsor = cinfo[0];
    let contentId = cinfo[1];
    let exposeApi = (window.ppnApi[id] = window.ppnApi[id] || {});
    initCore(id, container, sponsor, contentId, exposeApi);
}

window.ppnAutoIdCount = window.ppnAutoIdCount || 0;
function initByDomElement(container) {
    let idParts = container.id.split("-");
    idParts[2] = "auto:" + ++window.ppnAutoIdCount;
    container.id = idParts.join("-");

    let cinfo = idParts[1].split(":");
    let sponsor = cinfo[0];
    let contentId = cinfo[1];
    initCore(container.id, container, sponsor, contentId, {});
}

async function initCore(id, container, sponsor, contentId, exposeApi) {
    let overrideSponsor = mobilecheck() ? container.dataset.mobileSpid : container.dataset.desktopSpid;
    sponsor = overrideSponsor || sponsor;
    await loadPPNCustomScript(sponsor).catch(noop);
    MainView.create(id, container, sponsor, contentId, lepInfo, exposeApi);
}

init().catch((e) => {
    console.error("ad block");
});
