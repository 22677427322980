import postMsg from "./PostMsg.js";

export default function(ppnId, container, pageInfo){
    let isReady = false, wait = [];
    let msg = postMsg(ppnId, container);
    wait.push(() => msg.postData("set_pageInfo", { args: JSON.stringify(pageInfo) }));
    msg.one("ready", () => {
        isReady = true;
        wait.forEach(fn => fn());
        wait.length = 0;
        wait = null;
    });

    function postData(type, data) {
        if (isReady) {
            msg.postData(type, data);
        } else {
            wait.push(() => msg.postData(type, data));
        }
    }

    function getPuid(){
        return new Promise((resolve) => {
            msg.one("puid", data => resolve(data && data.puid));
            postData("get_puid");
        });
    }

    function setPuid(puidInfo) {
        postData("set_puid", { args: JSON.stringify(puidInfo) });
    }

    function gtag(...theArgs){
        let args = JSON.stringify(theArgs);
        postData("ga_tag", {args: args});
    }

    return {
        getPuid,
        setPuid,
        gtag
    };
}