import EVENT from './Events.js';
import UAParser from 'ua-parser-js';
import fetch from 'unfetch';
import ObserverPattern from './ObserverPattern.js';

export function testEnableAdBlock(){
    return new Promise(resolve => {
        fetch("https://fino.svc.litv.tv/acs/ads_check.js")
        .then(r => {
            if(r.ok == true) return r.text();
        })
        .then(r => {
            if(r) return fetch(r);
        })
        .then(() => resolve(false))
        .catch(e => resolve(true));
    });
    // return new Promise(resolve => {
    //    //fetch("https://fino.svc.litv.tv/ads/ads.js")
    //    fetch(`https://twproxy0${Math.floor(Math.random() * 3 + 1)}.svc.litv.tv/cdi/v2/rpc`)
    //    .then(() => resolve(false))
    //    .catch(e => resolve(true)); 
    // });
    /*
    try{
        google && true;
        google.ima && true;
        return false;
    }catch(e){
        console.error('May have "ad block"');
        return true;
    }
    */
}

export let toPromise = (() => {
    function isPromise(obj) {
        return !!obj && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function';
    }

    function verify(src, resolve, reject){
        let type = typeof src;
        if(type == "string") {
            resolve(src);
        }else if(isPromise(src)){
            src.then(resolve).catch(reject);
        }else if(type == "function"){
            verify(src(), resolve, reject);
        }else{
            reject("type error");
        }
    }

    return function(src){
        return function(){
            return new Promise((resolve, reject) => {
                verify(src, resolve, reject);
            }); 
        };
    }; 
})();

export let requestAnimationFrame = (() => {
    let _requestAnimationFrame = window.requestAnimationFrame && window.requestAnimationFrame.bind(window);
    let vendors = ['webkit', 'moz'];
    for (let i = 0; i < vendors.length && !_requestAnimationFrame; ++i) {
        let vp = vendors[i];
        _requestAnimationFrame = window[vp+'RequestAnimationFrame'].bind(window);
    }

    if (!_requestAnimationFrame) {
        let lastTime = 0;
        _requestAnimationFrame = callback => {
            let now = Date.now || new Date().getTime();
            let nextTime = Math.max(lastTime + 16, now);
            return setTimeout(() => {
                callback(lastTime = nextTime);
            }, nextTime - now);
        };
    }

    return () => new Promise(resolve => _requestAnimationFrame(resolve));
})();

export let passiveSupported = (() => {
    var passiveSupported = false;
    try {
        var options = Object.defineProperty({}, "passive", {
            get: function () {
                passiveSupported = true;
            }
        });
        window.addEventListener("test", null, options);
    } catch (err) {}
    return passiveSupported;
})();

export let passiveAndCapture = passiveSupported ? {
    passive: true,
    capture: true
} : true;

export let keyboradHandle = (vjsInfo) => {
    let ignore = false;
    let isStopped = false;

    function stop(){
        if(ignore || isStopped) return;
        vjsInfo.keyboard = false;
        isStopped = true;
    }

    function resume(){
        if(ignore || !isStopped) return;
        vjsInfo.keyboard = true;
        isStopped = false;
    }

    return {
        stop: stop,
        resume: resume,
        setMode: (mode) => {
            if(mode == "live" || mode == "simulation_live"){
                stop();
                ignore = true;
            }else{
                ignore = false;
            }
        }
    }
};

export function detectingPlatform(){
    const UA = navigator.userAgent;
    let isIOS = /iPad|iPhone|iPod/.test(UA) && !window.MSStream;
    let isIE = /(msie) ([\w.]+)|Trident/gi.test(UA);
    let isXP = /WINDOWS NT 5\.[\d]+/gi.test(UA);
    let isVista = /WINDOWS NT 6\.0+/gi.test(UA);
    let isWin8 = /WINDOWS NT 6\.[2,3]+/gi.test(UA);
    let isFirefox = /Firefox/.test(UA);
    let hasSafari = /Safari/.test(UA);
    let hasChrome = /Chrome/.test(UA);
    let inIframe = (() => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    })();

    let iosVersion = (() => {
        if(!isIOS) return null;
        let v = /OS ((?:\d+_?){2,3})\s/g.exec(UA);
        if(v == null || v.length < 1) return null;
        return v[1].replace("_", ".");
    })();

    let isMobile = (() => /ANDROID|IPHONE|IPAD|IPOD/i.test(UA))();

    let supportLocalStorage = (() => {
        try{
            if(typeof window.localStorage !== "object"){
                return false;
            }else{
                window.localStorage.LitvPlayerTestStorage = "test";
                window.localStorage.removeItem("LitvPlayerTestStorage");
                return true;
            }
        } catch(e){
            return false;
        }
    })();
    
    return {
        isIOS: isIOS,
        iosVersion: iosVersion,
        isIE: isIE,
        isXP: isXP,
        isVista: isVista,
        isWin8: isWin8,
        isFirefox: isFirefox,
        isSafari: hasSafari && !hasChrome,
        inIframe: inIframe,
        isIOSAndInIFrame: isIOS && inIframe,
        isMobile: isMobile,
        supportLocalStorage: supportLocalStorage
    };
}

export function versionComparison(left, right){
    let _l = (left || "").split(".");
    let _r = (right || "").split(".");
    for(let i = 0; i < 3; i++){
        let l = _l[i] || 0, r = _r[i] || 0;
        if(l > r) return 1;
        if(l < r) return -1;
    }
    return 0;
}

export let hidden, visibilityChange; 
if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support 
    hidden = "hidden";
    visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
}

export function ViewportSensor(threshold, container, cxt, triggerEvent, interObsProxy) {
    if (interObsProxy instanceof InterObsProxy) {
        interObsProxy.on(EVENT.IN_VIEWPORT, e => {
            cxt.inViewport = true;
            triggerEvent(EVENT.IN_VIEWPORT);
        });
        interObsProxy.on(EVENT.OUT_VIEWPORT, e => {
            cxt.inViewport = false;
            triggerEvent(EVENT.OUT_VIEWPORT);
        });
        return {
            get inViewPort() { return cxt.inViewport; },
            stop() {}
        };
    }
    let inViewport = cxt.inViewport && !document[hidden];
    const observer = new IntersectionObserver(onIntersectionChange, { threshold } );
    observer.POLL_INTERVAL = 100;
    observer.observe(container);
    document.addEventListener(visibilityChange, triggerViewportEvent);

    function onIntersectionChange(entries) {
        inViewport = getLatestIntersectionEntry(entries).isIntersecting;
        triggerViewportEvent();
    }
    function getLatestIntersectionEntry(entries) {
        // workaround, entries[0] whould not be lateset change currently.
        try {
            return entries.reduce((prev, current) => {
                let prevTime = +prev.time;
                let curTime = +current.time;
                return isNaN(prevTime) || curTime > prevTime ? current : prevTime;
            }, {});
        } catch (e) {
            return entries[0];
        }
    }
    function triggerViewportEvent() {
        let incoming = inViewport && !document[hidden];
        if (cxt.inViewport != incoming) {
            cxt.inViewport = incoming;
            triggerEvent(incoming ? EVENT.IN_VIEWPORT : EVENT.OUT_VIEWPORT);
        }
    }
    return {
        get inViewPort() {
            return inViewport;
        },
        stop() {
            observer.disconnect();
            document.removeEventListener(visibilityChange, triggerViewportEvent);
        }
    };
}

export function establishSourcesData(url){
    let types = [];
    if(/\.m3u8/.test(url)){
        types = ["application/x-mpegurl", "application/vnd.apple.mpegurl"];
    }else if(/\.mp4/.test(url)){
        types = ["video/mp4"];
    }                
    return types.map(type => ({
        type: type,
        src: url
    }));
}

export function establishUid(){
    return Math.random().toString(32).substring(2);
}

export function UiDisableControl(vjsPlayer){
    let _lock = false;
    let _disabled = !vjsPlayer.controls();
    let _uiDisable = (flag) => {
        _disabled = flag;
        if(_lock == true) return;
        vjsPlayer.controls(!_disabled);
    };

    return {
        lock: () => {
            _lock = true;
        },
        unlock: () => {
            _lock = false;
            _uiDisable(_disabled);
        },
        uiDisable: _uiDisable
    };
}

export function conditionClass(target, className, condition){
    if(condition == true){
        if(!target.classList.contains(className)){
            target.classList.add(className);
        }
    }else{
        if(target.classList.contains(className)){
            target.classList.remove(className);
        }
    }
}

function preFetchSvg(url){
    return fetch(url)
    .then(r => r.text())
    .then(r => {
        let svg = (new window.DOMParser()).parseFromString(r, "text/xml");
        svg = svg.documentElement;
        svg.style.width = 0;
        svg.style.height = 0;
        document.body.appendChild(svg);
        let bbox = svg.getBBox();
        document.body.removeChild(svg);
        return {url: url, width: Math.round(bbox.x + bbox.width), height: Math.round(bbox.y + bbox.height)};
    });
}

function preFetchImage(url){
    return new Promise((resolve, reject) => {
        let imgElement = new Image();
        imgElement.addEventListener('load', onLoaded);
        imgElement.addEventListener('error', onError);
        
        function onLoaded(){
            resolve({url:url, width:imgElement.width, height: imgElement.height});
            imgElement.removeEventListener('load', onLoaded);
            imgElement.removeEventListener('error', onError);
            imgElement = null;
        }
        
        function onError(e){
            reject(e);
            imgElement.removeEventListener('load', onLoaded);
            imgElement.removeEventListener('error', onError);
            imgElement = null;
        }
        
        imgElement.src = url;
    });
}

export function testImage(url){
    if(/(image\/svg\+xml)|(\.svg)/.test(url)){
        return preFetchSvg(url);
    }else{
        return preFetchImage(url);
    }
}

export const browserInfo = (() => {
    try{
        const parser = new UAParser();
        const parserResult = parser.getResult();
        let browserName = parserResult.browser.name.toLowerCase();
        let browserVersion = parserResult.browser.version;
        let browserVersionMajor = typeof(browserVersion) == "string" ? browserVersion.replace(/[^\d\.]/g,'').split(".")[0] : "0";
        let osName = parserResult.os.name.toLowerCase().slice(0,3);
        let osVersion = parserResult.os.version;
        if(/^mobile\ /i.test(browserName)){
            browserName = browserName.replace(/^mobile\ /i,"");
        }
        if(browserName.length > 3){
            let tempBN = browserName.split("");
            browserName = tempBN[0] + tempBN[1] + tempBN[tempBN.length - 1];
        }
        if(osVersion.indexOf(".") > -1){
            let tempOV = osVersion.split(".");
            osVersion = tempOV[0] + tempOV[1];
        }
        return browserName + browserVersionMajor + osName + osVersion;
    } catch(e){
        console.error(e);
        return "";
    }
})();

export const JsonrpcId = (() => {
    let jsonrpcId = -1;
    function getJsonRpcId(){
        jsonrpcId = ++jsonrpcId % 99999;
        return jsonrpcId + 1;
    }

    return {
        get: getJsonRpcId
    };
})();

export class SVGCope{
    constructor(svg) {
        let oriSvg = svg.split(",")[1];
        oriSvg = decodeURIComponent(escape(window.atob(oriSvg)));
        this.oriSvg = oriSvg;

        let _svg = this.SVGStringToElement(oriSvg);
        this.linearGradientIdList = Array.from(_svg.querySelectorAll("linearGradient")).map(linearGradient => linearGradient.id);
    }

    SVGStringToElement(svg){
        return (new window.DOMParser()).parseFromString(svg, "text/xml").documentElement;
    }

    getSVGElement(){
        let lgIdMap = this.linearGradientIdList.map(linearGradientId => {
            return {ori: linearGradientId, new: establishUid()};
        });

        let newSvg = lgIdMap.reduce((pre, lgIdMap) => {
            let n = pre.replace(new RegExp(lgIdMap.ori, "g"), lgIdMap.new);
            return n;
        }, this.oriSvg);

        return this.SVGStringToElement(newSvg);
    }
}

const ENV_TYPE = {
    LOCAL: "local",
    DEVELOPMENT: "development",
    STAGING: "staging",
    PRODUCTION: "production",
};

export const ENV_STRINGS = Object.keys(ENV_TYPE).map(key => ENV_TYPE[key]); // Object.value

export const ENV_DEFAULT = (function(){
    const locationHref = window.location.href;
    if(window.location.port != "" || /(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])/.test(locationHref)){
        return ENV_TYPE.LOCAL;
    }else if(/\/staging-|s-|dev-/.test(locationHref)){
        return ENV_TYPE.STAGING;
    }
    return ENV_TYPE.PRODUCTION;
})();

export function defaultValue(target, backup){
    if(typeof target == "undefined") {
        return backup;
    }else{
        return target;
    }
}

export class InterObsProxy extends ObserverPattern{
    constructor(){
        super();
        this._isIn = false;
    }
    inViewport(){
        this._isIn = true;
        this.trigger(EVENT.IN_VIEWPORT);
    }
    outViewport(){
        this._isIn = false;
        this.trigger(EVENT.OUT_VIEWPORT);
    }
    get isIn(){
        return this._isIn;
    }
}


export function checkInViewport(interObsProxy){
    if(interObsProxy){
        return interObsProxy.isIn;
    }else{
        return !document[hidden];
    }
}

// export function domBeIncluded(dom, targets, top){
//     if(!top) top = document.body;
//     function core(dom){
//         if(dom == null || dom == top) return false;
//         if(targets.includes(dom)) return true;
//         return core(dom.offsetParent);
//     }
//     return core(dom);
// }

function convertEids(eids, fnMap){
    let eidsValList = [];
    for(let prop in eids){
        if(fnMap[prop]){
            eidsValList.push(fnMap[prop](eids[prop]));
        }
    }
    return eidsValList;
}

export function genUCEids(eids){
    if(!eids) return "";
    let fnMap = {
        uid2: value => `uid2,${value}`,
        verizonMediaId: value => `verizonMediaId,${value.pixelId},${value.he}`  //TODO: 校驗
    };
    return convertEids(eids, fnMap).join("!");
}

export function genXEids(eids){
    if(!eids) return "";
    let fnMap = {
        uid2: value => `uidapi.com,${value},UID2`,
        verizonMediaId: value => `verizonMediaId,${value.pixelId},${value.he}`  //TODO: 校驗
    };
    return convertEids(eids, fnMap).join("|");
}

export function genYEids(eids){
    if(!eids) return "";
    let fnMap = {
        connectId: value => value
    };
    return convertEids(eids, fnMap)[0] || ""; //目前僅接受一個 id
}

export function genOriEids(eids){
    if(!eids) return "";
    let eidsValList = [];
    for (let prop in eids) {
        let vlist = [prop];
        let v = eids[prop];
        if(typeof(v) === "string"){
            vlist.push(v);
        }else if(v instanceof Array){
            vlist = vlist.concat(v);
        }
        eidsValList.push(vlist.join(","))
    }
    return eidsValList.join("!");
}

export function genUuid() {
    let r =
        typeof window.crypto != "undefined" && typeof window.crypto == "function"
            ? () => crypto.getRandomValues(new Uint8Array(1))[0]
            : () => (Math.random() * 256) | 0;
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ (r() & (15 >> (c / 4)))).toString(16));
}

export function getLocalStoreSafe(key) {
    try {
        return JSON.parse(localStorage.getItem(key));
    } catch (e) {}
    return null;
}

export function setLocalStoreSafe(key, obj) {
    try {
        localStorage.setItem(key, JSON.stringify(obj));
    } catch (e) {}
}

export function clamp(val, max, min) {
    if (typeof val != "number") {
        return min;
    }
    return val < max ? (val > min ? val : min) : max;
}

export class Deferred {
    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        })
    }
}

export function noop() {}