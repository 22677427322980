import 'intersection-observer';
import { testEnableAdBlock, toPromise, requestAnimationFrame, keyboradHandle, detectingPlatform, ViewportSensor, establishSourcesData, visibilityChange, UiDisableControl, browserInfo, defaultValue, InterObsProxy, checkInViewport, versionComparison, passiveAndCapture, ENV_DEFAULT, ENV_STRINGS } from './Util.js';
import AssignImage from './AssignImage.js';
import CountdownComponent from './CountdownComponent.js';
import displayAdsApi from './DisplayAdsAPI';
import EVENT from './Events.js';
import ImageAd from './ImageAd.js';
import HouseVideoAd from './HouseVideoAd.js';
import ImaPack from './ImaPack.js';
import PinAdController from './PinAdController.js';
import LiAd from './LiAd.js';
import ObserverPattern from './ObserverPattern.js';
import PlayedCount from './PlayedCount.js';
import PRELOAD_TYPE from './PreloadType.js';
import StreamingSessionControl from './StreamingSessionControl.js';
import customizeVjs from './customizeVjs.js';
import setUpView from './SetupMainView.js';
import Trace from './Trace.js';
import UrlConfig from './UrlConfig.js';
import PalHandler from './palHandler.js';
import WaitPageViewable from './functional/waitPageViewable.js';
import CheckAutoplaySupport from './functional/CheckAutoplaySupport.js';

const errorTip = "播放失敗, 請重新載入頁面";

export default class Player extends ObserverPattern {
    constructor(container, options = {}, version) {
        super();
        this.isFirst = true;
        this.platformInfo = detectingPlatform();
        this.noVolume = false; // !vjsPlayer.tech().featuresVolumeControl / featuresMuteControl
        this.defOpt = Object.assign({}, {
            useNativeFullscreen: false,
            disableFullscreen: false,
            decideVisible: false,
            visibleThreshold: 0.3,
            preloadThreshold: null,
            checkContinueStream: null,
            stopContinueStream: null,
            displayClickToUnMute: false,
            allowAdPause: false,
            preloadType: PRELOAD_TYPE.THRESHOLD,
            appInfo: "",
            cover: null,
            adRequestTimeout: 8E3,
            brand: false,
            customApiUrlConfig: undefined,
            interObsHosting: false,
            vpaidMode: "ENABLE",
            vastClickArea: 'default',
            env: null
        }, options);

        if (ENV_STRINGS.indexOf(this.defOpt.env) < 0) {
            this.defOpt.env = ENV_DEFAULT;
        }

        if (this.defOpt.appInfo !== "") {
            this.defOpt.appInfo += "|";
        }
        this.defOpt.appInfo += ("pv." + version + "|" + browserInfo);

        this._container = container;
        this._src = null;
        this._config = {
            errors: [
                // video exceptions
                '',
                `${errorTip} (Video loading aborted)`,
                `${errorTip} (Network error)`,
                `${errorTip} (Video not properly encoded)`,
                `${errorTip} (Video file not found)`,

                // player exceptions
                `${errorTip} (Unsupported video)`,
                `${errorTip} (Skin not found)`,
                `${errorTip} (SWF file not found)`,
                `${errorTip} (Subtitles not found)`,
                `${errorTip} (Invalid RTMP URL)`,
                `${errorTip} (Unsupported video format. Try installing Adobe Flash.)`,
            ]
        };

        this.urlConfig = new UrlConfig(this.defOpt.env);
        if (this.defOpt.customApiUrlConfig) {
            this.urlConfig.setConfig(this.defOpt.customApiUrlConfig);
        }

        if (this.defOpt.interObsHosting == true) {
            this.interObsProxy = new InterObsProxy();
        } else {
            this.interObsProxy = null;
        }

        if (this.defOpt.useNativeFullscreen == false) {
            this.defOpt.useNativeFullscreen = canUseNativeFullscreen(document);
            function canUseNativeFullscreen(document) {
                return typeof document.webkitFullscreenEnabled === "boolean"
                    ? document.webkitFullscreenEnabled
                    : (typeof document.webkitCancelFullScreen == "function" &&
                          !/Mac OS X 10_5.+Version\/5\.0\.\d Safari/.test(navigator.userAgent)) ||
                          document.mozFullScreenEnabled ||
                          typeof document.exitFullscreen == "function" ||
                          typeof document.msExitFullscreen == "function";
            }
        }

        this.abortPreSrc = () => { };
        this.abortPreReqSrc = () => { };
        this.playedCount = new PlayedCount();
        this.programEndTime = null;
        this.inlinearAd = false;
        this.inSetSrcThrottle = false;
        this.temporarySrcInfo = {};
        this.temporarySrcInfoUsed = true;
        this.isStopped = true;
        this.inExecution = false;
        this.inViewport = !this.defOpt.decideVisible;
        this.onViewportChange = this.onViewportChange();
        this.waitInViewport = this.waitInViewport();
        this.waitClick = this.waitClick();
        this.queue = [];
        this.pauseAdDelayTimer = null;
        this.isSeekingActionTimer = null;
        this.handleContentPlayerEvents = this.handleContentPlayerEvents();
        this.ignoreDocumentHiddenCheck = false;
        this.clip = {};
        let triggerEvent = super.trigger.bind(this);
        this.vjsPlayer = customizeVjs(container, this, this.defOpt, triggerEvent);
        this.vjsInfo = vjsInfoBase();
        this.vjsPlayer.volume(this.vjsInfo.volume);
        this.uiDisableControl = UiDisableControl(this.vjsPlayer);
        this.uiDisable = this.uiDisableControl.uiDisable;
        this.keyboradHandle = keyboradHandle(this.vjsInfo);
        this.palHandler = PalHandler(this.vjsPlayer.el(), "video.js", videojs.VERSION);
        // === Setup Viewer ===
        let {
            playerContainer,
            logoContainer,
            linearImageContainer,
            houseVideoAdContainer,
            nonlinearImageContainer,
            imaAdContainer,
            playIcon,
            MainViewOperator
        } = setUpView(container, this.vjsPlayer, this, triggerEvent, Player.prototype, this.platformInfo, this.noVolume, this.defOpt.brand);
        this.MainViewOperator = MainViewOperator;
        if (this.defOpt.decideVisible) {
            this.viewportSensor = ViewportSensor(this.defOpt.visibleThreshold, playerContainer, this, triggerEvent, this.interObsProxy);
        }
        this.unmeuntMask = UnmeuntMask(this);
        // === Initial above with queue ability ===
        this.queueNext = this.queueNext.bind(this);
        ["play", "pause", "seek"].forEach(fnName => {
            this[fnName] = this.decorateAction(this[fnName], fnName);
        });
        let vjsPlayer = this.vjsPlayer;
        let contentPlayerProxy = {
            get currentTime() {
                return vjsPlayer.currentTime();
            },
            get isFullscreen() {
                return vjsPlayer.isFullscreen();
            },
            get supportFullscreen() {
                return vjsPlayer.supportsFullScreen();
            },
            get muted() {
                return vjsPlayer.muted();
            },
            get volume() {
                return vjsPlayer.volume();
            }
        };
        // ===  ===
        let adBlockEnable = testEnableAdBlock();
        this.ssControl = new StreamingSessionControl(this.defOpt.checkContinueStream, this.defOpt.stopContinueStream);
        this.imaPack = new ImaPack(adBlockEnable, imaAdContainer, contentPlayerProxy, this.platformInfo, this.defOpt.adRequestTimeout, this.defOpt.vpaidMode, this.defOpt.vastClickArea);
        this.houseVideoAd = new HouseVideoAd(houseVideoAdContainer, contentPlayerProxy, this.platformInfo, this.urlConfig);
        this.imageAd = new ImageAd(adBlockEnable, logoContainer, nonlinearImageContainer, linearImageContainer, playerContainer, this.urlConfig, this.viewportSensor);
        this.pinAdController = new PinAdController(this.urlConfig);
        this.countdownComponent = this.initCountdownComponent(playerContainer);
        this.liAd = this.initLiAd(this.imaPack, this.houseVideoAd, this.imageAd, this.pinAdController, this.countdownComponent, this.playedCount, this.urlConfig, this.interObsProxy);
        playIcon.onmouseup = e => this.play();
        playIcon.ontouchend = e => this.play();
        displayAdsApi(this, this.liAd);
        // === Register Flowplayer Events ===
        function round(val) {
            return val ? Math.round(val * 100) / 100 : 0;
        }
        this.vjsPlayer.on("volumechange", e => {
            const volumeLevel = round(this.vjsPlayer.volume());
            const muted = this.vjsPlayer.muted() || volumeLevel == 0;
            const wasMuted = this.vjsInfo.muted;
            this.vjsInfo.saveVolume(volumeLevel, muted);
            muted ? container.classList.add("is-muted") : container.classList.remove("is-muted");
            if (wasMuted != muted) {
                this.mute(muted);
                triggerMuteEvent(e, muted);
            }
            if (!muted) {
                let adVolumeLevel = volumeLevel * 0.5;
                this.imaPack.setVolume(adVolumeLevel);
                this.houseVideoAd.setVolume(volumeLevel);
            }
            triggerVolumeEvent(e, volumeLevel);

            function triggerMuteEvent(event, muted) {
                event.muted = muted;
                event.getCarryInfo = () => ({ muted });
                triggerEvent(EVENT.MUTE, event, muted);
            }

            function triggerVolumeEvent(event, volumeLevel) {
                event.volumeLevel = volumeLevel;
                event.getCarryInfo = () => ({ volumeLevel });
                triggerEvent(EVENT.VOLUME, event, volumeLevel);
            }
        });
        let onFullscreenEvent = (type) => {
            this.imaPack.resize();
            super.trigger(type, { type });
        }
        this.vjsPlayer.on("fullscreenchange", () => {
            if (this.defOpt.useNativeFullscreen) {
                onFullscreenEvent(vjsPlayer.isFullscreen() ? EVENT.FULLSCREEN : EVENT.FULLSCREEN_EXIT);
            }
        });
        this.vjsPlayer.on("enterFullWindow", () => onFullscreenEvent(EVENT.FULLWINDOW));
        this.vjsPlayer.on("exitFullWindow", () => onFullscreenEvent(EVENT.FULLWINDOW_EXIT));
        this.vjsPlayer.on("ratechange", () => {
            let level = ((this.vjsPlayer.playbackRate() * 100) | 0) / 100; // round 0.01.
            let eventData = {
                getCarryInfo: () => {
                    return { level };
                },
                level: level
            };
            super.trigger(EVENT.SPEED, eventData, level);
        });
        this.vjsPlayer.one('loadedmetadata', () => {
            let video = this.getMediaInfo();
            let eventData = {
                getCarryInfo: () => {
                    return { video };
                },
                video: video
            };
            super.trigger(EVENT.READY, eventData, video);

            if (!this.vjsPlayer.tech().vhs) {
                return;
            }
            let qualityLevels = this.vjsPlayer.qualityLevels();
            qualityLevels.on('change', () => {
                let index = qualityLevels.selectedIndex;
                let eventData = {
                    getCarryInfo: () => {
                        return { index };
                    },
                    index: index
                };
                super.trigger(EVENT.QUALITY, eventData, index);
            });
        });
        this.vjsPlayer.on("error", e => {
            container.classList.add("is-error");
            let error = this.vjsPlayer.error();
            if (error && error.code) {
                this.vjsPlayer.errorDisplay.fillWith(this._config.errors[error.code]);
            }

            let eventData = {
                getCarryInfo: () => { return { error }; },
                error: error
            };
            super.trigger(EVENT.ERROR, eventData, error);
        });
        this.vjsPlayer.on("playerresize", () => {
            this.imaPack.resize();
        });

        // === Register House Image AD Events ===
        this.imageAd.on("click", e => {
            if (e.is_logo == true) {
                super.trigger(EVENT.CLICK_LOGO, e);
            } else if (e.is_linear == false) {
                super.trigger(EVENT.CLICK_PAUSE_BANNER, e);
            }
        });

        this.imageAd.on("impression", e => {
            if (e.is_logo == false && e.is_linear == false) {
                super.trigger(EVENT.PAUSE_BANNER_IMPRESSION, e);
            }
        });

        // === Register Pin AD Events ===
        this.pinAdController.on("publish", e => {
            super.trigger(EVENT.PUBLISH_COMPANION_AD, e);
        });

        this.pinAdController.on("requestHide", e => {
            super.trigger(EVENT.COLLAPSE_COMPANION_AD, e);
        });

        // ===  StreamingSessionControl Events ===
        this.ssControl.on("error", e => {
            super.trigger(EVENT.MULTIPLE_ACCOUNT_USING, e);
        });

        this.ssControl.on("typeError", e => {
            super.trigger(EVENT.ERROR, { message: "checkContinueStream type error", error: e });
        });

        //===  ===
        let assignImage = new AssignImage(this.imageAd);
        assignImage
            .on("PauseRequested", e => {
                if (e.disableControl == true) {
                    this.keyboradHandle.stop();
                    this.inlinearAd = true;
                    this.uiDisable(true);
                    this._container.classList.add("linear_ad_impression");
                    this._container.setAttribute("data-media-type", "image");
                    this.handleContentPlayerEvents(false);
                    this.vjsPlayer.pause();
                } else {
                    this.vjsPlayer.pause();
                    this.vjsPlayer.one("resume", assignImage.removeAll);
                    setTimeout(this.abortPauseAd, 100);
                }
            })
            .on("AdMediaComplete", e => {
                if (e.is_linear == false) {
                    return;
                }
                this.keyboradHandle.resume();
                this.inlinearAd = false;
                this.uiDisable(false);
                this._container.classList.remove("linear_ad_impression");
                this._container.classList.remove("ad_paused");
                this._container.removeAttribute("data-media-type");
                this.play();
                this.handleContentPlayerEvents(true);
            });

        // ==  ==
        this.assignImage = function (opt, disableControl) {
            return assignImage.assign(opt, disableControl);
        };

        // ==  == //XXX 暫時作法
        // window.addEventListener("resize", () => {
        //     this.imaPack.resize();
        // })
    }

    initCountdownComponent(container) {
        let countdownComponent = new CountdownComponent(container);
        countdownComponent.on("click", (e) => {
            super.trigger(EVENT.CLICK_SKIP, e);
        });
        return countdownComponent;
    }

    initLiAd(imaPack, houseVideoAd, imageAd, pinAdController, countdownComponent, playedCount, urlConfig, interObsProxy) {
        let liAd = new LiAd(imaPack, houseVideoAd, imageAd, pinAdController, countdownComponent, playedCount, urlConfig, interObsProxy);

        let pauseContent = () => this.vjsPlayer.pause();
        // remark: 2023/07/11
        // looks like chrome would pause ad while doc hidden for some cases (unsure, related to mute state)
        // use local flag to avoid ad resuming after click through as workaround.
        let isPauseByClickThrough = false;

        liAd
            .on("LinearAdStart", e => {
                this.inlinearAd = true;
                this.uiDisable(true);
                this._container.classList.add("linear_ad_impression");
                this.vjsPlayer.one("timeupdate", pauseContent);
                if (e.partType !== "midrolls") {
                    // this.fplayer.on("beforeseek.inLinearAd", e => {
                    //     e.preventDefault();
                    // });
                }
            })
            .on("LinearAdComplete", e => {
                // this.fplayer.off(".inLinearAd");
                this.liAd.logo().start().catch(e => !e.isWarning && console.error(e));
                this.vjsPlayer.off("timeupdate", pauseContent);
                this.inlinearAd = false;
                this.uiDisable(false);
                this._container.classList.remove("linear_ad_impression");
                this._container.classList.remove("ad_paused");
                this._container.removeAttribute("data-media-type");
                if (this.lookAfterTemporarySrc(e.partType) == true) e.preventDefault();
            })
            .on("RequestPause", e => {
                this.keyboradHandle.stop();
                this.handleContentPlayerEvents(false);
                this.vjsPlayer.pause();
                this.imageAd.closeNonLinearBanner();
                if (!this.clip.live && e.rewind) {
                    let seekTime = this.vjsPlayer.currentTime() + e.rewind;
                    seekTime = Math.max(seekTime, 0);
                    this.vjsPlayer.currentTime(seekTime);
                }
                // this.fplayer.on("beforeseek.inLinearAd", e => {
                //     e.preventDefault();
                // });
                super.trigger(EVENT.PAUSE_FOR_AD, e);
            })
            .on("RequestResum", e => {
                // this.fplayer.off(".inLinearAd");
                this._container.classList.remove("ad_paused");
                this.keyboradHandle.resume();
                //if(this.ignoreDocumentHiddenCheck == true || !document[hidden]) {
                if (this.ignoreDocumentHiddenCheck == true || checkInViewport(this.interObsProxy)) {
                    this.play();
                } else {
                    this.requestPauseAd();
                }
                this.handleContentPlayerEvents(true);
                super.trigger(EVENT.RESUM_FOR_AD, e);
            })
            .on("ResetPauseAndResum", e => {
                this._container.classList.remove("linear_ad_impression");
                this._container.classList.remove("ad_paused");
                this._container.removeAttribute("data-media-type");
            })
            .on("AdRequest", e => {
                if (e.isLinear == true && this.vjsPlayer.paused()) {
                    this._container.classList.add("is-loading");
                }
                super.trigger(EVENT.AD_REQUEST, e);
            })
            .on("Impression", e => {
                if (e.isLinear == true) {
                    checkAndRemoveCover.apply(this);
                }
            })
            .on("AdMediaStart", e => {
                e.duration = e.duration * 1000;
                this._container.setAttribute("data-media-type", e.mediaType)
                if (e.isInteractive) {
                    this._container.classList.add("interactive_ad");
                } else {
                    this._container.classList.remove("interactive_ad");
                }
                if (e.isLinear) {
                    this._container.classList.remove("is-loading");
                    super.trigger(EVENT.LINEAR_AD_MEDIA_START, e, e.duration);
                } else {
                    super.trigger(EVENT.NONLINEAR_AD_MEDIA_START, e, e.duration);
                }
            })
            .on("CompanionAd", e => {
                super.trigger(EVENT.PUBLISH_COMPANION_AD, e);
            })
            .on("CompanionAdEnd", e => {
                super.trigger(EVENT.COLLAPSE_COMPANION_AD, e);
            })
            .on("AdMediaComplete", e => {
                if (e.isLinear) {
                    this._container.classList.remove("interactive_ad");
                    super.trigger(EVENT.LINEAR_AD_MEDIA_COMPLETE, e);
                } else {
                    super.trigger(EVENT.NONLINEAR_AD_MEDIA_COMPLETE, e);
                }
            })
            .on("Error", e => {
                if (e.isLinear == true && e.error.fatal !== false) this._container.classList.remove("is-loading");
                super.trigger(EVENT.AD_ERROR, e);
            })
            .on("MetaError", e => {
                console.error("MetaError");
                super.trigger(EVENT.AD_ERROR, { message: "LiAd Meta Error", liad: e });
            })
            .on("Report", e => {
                super.trigger(EVENT.AD_REPORT, e);
            })
            .on("Progress", e => {
                super.trigger(EVENT.AD_PROGRESS, e);
            })
            .on("AdStreamComplete", e => {
                super.trigger(EVENT.AD_STREAM_COMPLETE, e);
            })
            .on("AdStreamCancel", e => {
                if (e.isLinear == true) {
                    this._container.classList.remove("is-loading");
                    this.vjsPlayer.off("timeupdate", pauseContent);
                    this.inlinearAd = false;
                }
            })
            .on("AdClick", e => {
                if (!this.inlinearAd || this.paused) {
                    return
                }
                let pauseAdIfHidden = () => {
                    if (!checkInViewport(this.interObsProxy)) {
                        isPauseByClickThrough = true
                        this.linearAd$pause();
                        document.addEventListener(visibilityChange, resumeAd);
                    }
                }
                let resumeAd = () => {
                    if (checkInViewport(this.interObsProxy)) {
                        isPauseByClickThrough = false
                        this.linearAd$play();
                        document.removeEventListener(visibilityChange, resumeAd);
                    }
                }
                setTimeout(pauseAdIfHidden, 300);
            })
            .on("AdPause", e => {
                if (e.isInteractive) {
                    return;
                }
                if (this.defOpt.allowAdPause || isPauseByClickThrough) {
                    this._container.classList.add("ad_paused");
                    return;
                }
                this.linearAd$play();
            })
            .on("AdResume", e => {
                this._container.classList.remove("ad_paused");
            })
        // .on("AdMuted", e => {
        //     this.vjsPlayer.muted(e.muted);
        // });
        return liAd;
    }

    getMediaInfo() {
        let player = this.vjsPlayer;
        let qualities = player.qualityLevels();
        let origMedia = player.getMedia();
        let source = player.currentSource();
        return {
            cuepoints: this.cuepoints,
            dvr: origMedia.dvr,
            height: player.videoHeight(),
            live: origMedia.live,
            qualities: qualities.levels_,
            quality: qualities.selectedIndex,
            sources: origMedia.src,
            src: source.src,
            type: source.type,
            get url() {
                let vhs = player.tech().vhs;
                return vhs ? vhs.mediaSourceUrl_ : "";
            },
            get seekable() {
                let range = player.seekable();
                return range.length > 0 ? range.end(0) : null;
            },
            get buffer() { return player.bufferedEnd(); },
            get duration() { return player.duration() },
            get time() { return player.currentTime() },
            get height() { return player.videoHeight() },
            get width() { return player.videoWidth() },
        };
    }

    handleContentPlayerEvents() {
        let checkCuePoint = () => {
            let cuepoints = this.cuepoints;
            let time = this.vjsPlayer.currentTime();
            let id = cuepoints.findIndex(cue => (cue.time < time && time <= cue.time + 0.5));
            if (id < 0) {
                return;
            }

            let duration = cuepoints[id].duration;
            cuepoints.splice(id, 1);
            if (cuepoints.length == 0) {
                this.vjsPlayer.off("timeupdate", checkCuePoint);
            }
            this.requestMidroll({ duration });
        }
        let onProgress = e => this.onProgress(e);
        let onSeek = e => this.onSeek(e);
        let onSeekBarClick = e => this.clip.live ? null : this.requestMidroll();
        let onFinish = e => this.onFinish(e);
        let onPause = e => this.onPause(e);
        let onResume = e => this.onResume(e);
        let onControlsdisabled = e => super.trigger(EVENT.DISABLE, e);
        let enableContentPlayerEvents = () => {
            let vjsPlayer = this.vjsPlayer;
            if (this.cuepoints.length > 0) vjsPlayer.on("timeupdate", checkCuePoint);
            vjsPlayer.on("timeupdate", onProgress);
            vjsPlayer.on("seeked", onSeek);
            vjsPlayer.on("ended", onFinish);
            vjsPlayer.on("pause", onPause);
            vjsPlayer.on("play", onResume);
            vjsPlayer.on("controlsdisabled", onControlsdisabled);
            vjsPlayer.controlBar.progressControl.seekBar.on('click', onSeekBarClick);
        }
        let disableContentPlayerEvents = () => {
            let vjsPlayer = this.vjsPlayer;
            if (this.cuepoints.length > 0) vjsPlayer.off("timeupdate", checkCuePoint);
            vjsPlayer.off("timeupdate", onProgress);
            vjsPlayer.off("seeked", onSeek);
            vjsPlayer.off("ended", onFinish);
            vjsPlayer.off("pause", onPause);
            vjsPlayer.off("play", onResume);
            vjsPlayer.off("controlsdisabled", onControlsdisabled);
            vjsPlayer.controlBar.progressControl.seekBar.off('click', onSeekBarClick);
        }
        let _enable = false;
        return function (enable = true) {
            if (enable == _enable) return;
            _enable = enable;
            if (enable) {
                enableContentPlayerEvents();
            } else {
                disableContentPlayerEvents();
            }
        }
    }

    onProgress() {
        let time = this.vjsPlayer.currentTime();
        this.playedCount.timeChange(time, +new Date);

        time *= 1000;
        super.trigger(
            EVENT.PROGRESS, {
            getCarryInfo: () => { return { time }; },
            time
        },
            time
        );
    }

    onSeek(e) {
        let position = this.vjsPlayer.currentTime();
        if (this.isSeekingActionTimer != null) {
            clearTimeout(this.isSeekingActionTimer);
            this.isSeekingActionTimer = null;
        }

        this._container.classList.add("is-seeking-action");
        this.isSeekingActionTimer = setTimeout(() => {
            this._container.classList.remove("is-seeking-action");
        }, 1000);

        if (this.inlinearAd == true) {
            if (this.vjsPlayer.paused() == false) {
                this.vjsPlayer.pause();
            }
        }
        // else{
        //     if(!this.clip.live) this.requestMidroll();
        // }
        e.position = position;
        e.getCarryInfo = () => {
            return { position: position };
        };
        super.trigger(EVENT.SEEK, e, position); //TODO 評估是否要 midroll 完才發 event
    }

    onFinish(e) {
        super.trigger(EVENT.FILM_FINISH, e);
        this.abortPauseAd();
        this.pinAdController.close();
        this.liAd.postrolls({ endTime: this.programEndTime }).start()
            .then(() => {
                this._container.classList.add("is-ended");
                super.trigger(EVENT.ENDED, { currentInfo: this.currentInfo });
            })
            .catch((e) => {
                !e.isWarning && console.error(e);
                this._container.classList.add("is-ended");
                super.trigger(EVENT.ENDED, { currentInfo: this.currentInfo });
            });
    }

    onPause(e) {
        let ec = super.trigger(EVENT.PAUSE, e);
        if (ec.defaultPrevented) return;
        this.pauseAdDelayTimer = setTimeout(this.requestPauseAd.bind(this), 300);
    }

    onResume(e) {
        this._container.classList.remove("is-ended");
        super.trigger(EVENT.RESUME, e);
        this.abortPauseAd();
        this.imageAd.closeNonLinearBanner();
    }

    abortPauseAd() {
        if (this.pauseAdDelayTimer != null) {
            clearTimeout(this.pauseAdDelayTimer);
            this.pauseAdDelayTimer = null;
        }
    }

    decorateAction(fn, fnName) {
        return function () {
            if (this.inlinearAd == true) {
                let nFnName = "linearAd$" + fnName;
                if (nFnName in this) {
                    this[nFnName].apply(this, arguments);
                }
                return this;
            }
            if (!this.inExecution) {
                this.inExecution = true;
                fn.apply(this, arguments);
            } else {
                this.queue.push({ fn: fn, arg: arguments });
            }
            return this;
        }
    }

    linearAd$play() {
        this.liAd.resume();
    }

    linearAd$pause() {
        this.liAd.pause();
    }

    queueNext() {
        this.inExecution = false;
        if (this.queue.length == 0) return;
        let { fn, arg } = this.queue.shift();
        fn.apply(this, arg);
    }

    cleanQueue() {
        this.inExecution = false;
        this.queue = [];
    }

    requestMidroll(condition = {}) {
        return this.liAd.midrolls(condition).start().catch(e => !e.isWarning && console.error(e));
    }

    requestPauseAd() {
        this.liAd.stopLinearAd("requestPauseAd");
        return this.liAd.pauseAd().start().catch(e => !e.isWarning && console.error(e));
    }

    setSrcThrottle() {
        this.inSetSrcThrottle = true;
        requestAnimationFrame()
            .then(() => {
                this.inSetSrcThrottle = false;
                if (this.temporarySrcInfoUsed == true) return;
                this.resetSrcByTemporary();
            });
    }

    lookAfterTemporarySrc(partType) {
        if (this.temporarySrcInfoUsed == true) return false;
        this.abortPreSrc();
        this.temporarySrcInfo.isWakeFromKeepPlayingAd = true;
        Promise.resolve().then(() => this.resetSrcByTemporary());
        return true;
    }

    resetSrcByTemporary() {
        this.setSrcCore(this.temporarySrcInfo);
        this.temporarySrcInfoUsed = true;
    }

    setSrcCore({
        autoPlay,
        src,
        sessionId,
        startTime, // startTime 單位毫秒
        liadMeta,
        playAds,
        assetId,
        midrollTimeCodes,
        midrollTimecodeDuration,
        mediaMode = "vod", // "vod", "live", "simulation_live"
        keepPlayingAd = false,
        programEndTime = null,
        midrollBeforeStart = false,
        midrollBeforeStartDuration = 0,
        isWakeFromKeepPlayingAd = false,
        adUrlReplacement = [],
        muted = false,
        cover = null,
        caption,
        enableCountdown,
        hiddenCheck,
        companionAdSize,
        puid,
        eids,
        getHouseAdUrl,
        getAdUrl
    }) {

        // === ===
        this.temporarySrcInfo = {
            autoPlay,
            assetId,
            liadMeta,
            playAds,
            programEndTime,
            src,
            sessionId,
            startTime,
            keepPlayingAd,
            mediaMode,
            midrollTimeCodes,
            midrollTimecodeDuration,
            midrollBeforeStart,
            midrollBeforeStartDuration,
            adUrlReplacement,
            muted,
            cover,
            caption,
            enableCountdown,
            hiddenCheck,
            companionAdSize,
            puid,
            eids,
            getHouseAdUrl,
            getAdUrl
        };
        if (this.inSetSrcThrottle == true || (this.inlinearAd == true && keepPlayingAd == true)) {
            this.countdownComponent.setThumbnail(typeof cover === "string" ? cover : "");
            this.countdownComponent.showThumbnail();
            this.temporarySrcInfoUsed = false;
            return;
        }

        // === ===
        this.keyboradHandle.setMode(mediaMode);
        this.keyboradHandle.stop();

        this.imaPack.setCompanionAdSize(companionAdSize);
        this.imaPack.setPuid(puid);
        this.imaPack.setEids(eids);
        this.liAd.setPuid(puid);
        this.houseVideoAd.setPuid(puid);
        
        this.houseVideoAd.setGetHouseAdUrl(getHouseAdUrl);
        this.houseVideoAd.setGetAdUrl(getAdUrl);

        this.programEndTime = programEndTime;
        //this.fplayer.stop();
        this.handleContentPlayerEvents(false);
        // this.fplayer.off(".backup");
        // this.fplayer.off(".setSrc");
        // this.fplayer.off(".inLinearAd");
        this.vjsPlayer.pause();
        this.abortPreSrc();
        this.cleanQueue();
        this.inExecution = true;
        this.playedCount.reset();
        this.uiDisable(true);
        this.isStopped = false;
        this._container.classList.remove("is-ended");
        this._container.classList.remove("is-ready");
        this._container.classList.remove("is-stopped");
        this._container.classList.remove("is-loaded");
        this._container.classList.remove("is-start");
        this._container.classList.remove("is-poster");
        this._container.classList.add("is-preparing");
        this._container.classList.remove("is-error");
        this.vjsPlayer.errorDisplay.hide();
        if (typeof cover === "string") {
            this._container.classList.add("is-poster");
            this._container.style.setProperty("background-image", `url('${cover}')`, "important");
            this.countdownComponent.setThumbnail(cover);
        } else {
            this.countdownComponent.setThumbnail("");
        }
        this.removeListenViewportStatus();
        this.vjsPlayer.upperToolBar.caption.update(caption);
        // === ===
        let isFirst = this.isFirst;
        this.isFirst = false;
        let isLive = false;
        let disableCountdown = false;
        this.container.classList.remove("is-live");
        if (mediaMode == "live") {
            this.container.classList.add("is-live");
            isLive = true;
            disableCountdown = true;
            this.ignoreDocumentHiddenCheck = !defaultValue(hiddenCheck, false);
            this.clip.live = true;
            this.clip.dvr = false;
        } else if (mediaMode == "simulation_live") {
            this.container.classList.add("is-live");
            disableCountdown = true;
            this.ignoreDocumentHiddenCheck = !defaultValue(hiddenCheck, false);
            this.clip.live = false;
            this.clip.dvr = true;
        } else {
            this.clip.live = false;
            this.clip.dvr = false;
            this.ignoreDocumentHiddenCheck = !defaultValue(hiddenCheck, true);
        }
        if (typeof enableCountdown === "boolean") {
            disableCountdown = !enableCountdown;
        }
        // === ===
        this.ssControl.pause();
        this.ssControl.skip(playAds);
        this.ssControl.start(sessionId);
        this._src = toPromise(src);
        this.liAd.setMeta(liadMeta, playAds, assetId, disableCountdown, midrollTimeCodes, midrollTimecodeDuration, startTime, adUrlReplacement, this.ignoreDocumentHiddenCheck);
        this.cuepoints = this.liAd.getMidrollTimeCodes().map(timeCodeInfo => {
            return {
                time: timeCodeInfo.time,
                duration: timeCodeInfo.duration
            };
        });
        // 終止前一個請求。這方式不漂亮，或許該考慮改用 Rx。
        this.abortPreReqSrc();
        this.waitInViewport.cancel();
        this.waitClick.cancel();
        let cancelled = false;
        this.abortPreSrc = () => {
            cancelled = true;
            this.abortPreReqSrc();
            this.waitInViewport.cancel();
            this.waitClick.cancel();
        };
        let loadVideo = () => {
            return this._src()
                .then(url => {
                    if (typeof url == "undefined") {
                        throw new Error("InvalidSrc: failed to get source or no set source");
                    }
                    if (!url) {
                        throw new Error("InvalidSrc: " + url);
                    }
                    return this.palHandler.replace(url, puid);
                })
                .then(url => {
                    return new Promise(resolve => {
                        this.handleContentPlayerEvents(false);
                        this.vjsPlayer.src(establishSourcesData(url));
                        this.vjsPlayer.ready(resolve);
                        const video = this.getMediaInfo();
                        super.trigger(EVENT.LOAD, { video, getCarryInfo: () => video }, video);
                    });
                })
                .then(() => {
                    this._container.classList.remove("is-preparing");
                    this._container.classList.add("is-loaded");
                    if (isLive) {
                        return;
                    }
                    const vjsPlayer = this.vjsPlayer;
                    const onSeekToStartTime = () => vjsPlayer.currentTime(startTime / 1000);
                    if (!this.platformInfo.isSafari || vjsPlayer.readyState() >= 3) {
                        return onSeekToStartTime();
                    }
                    // note:
                    // 1. currentTime() does not work inside vjsPlayer.ready() in safari.
                    // sometimes even later like until canplay is fired.
                    // https://github.com/videojs/video.js/issues/4696
                    // 2. vjsPlayer.readyState() >= 3: HAVE_FUTURE_DATA
                    vjsPlayer.one("canplay", onSeekToStartTime);
                });
        };

        // === Start ===
        Promise.resolve()
            .then(async () => {
                if (isFirst && autoPlay) {
                    const autoPlayState = await WaitPageViewable().promise().then(CheckAutoplaySupport);
                    autoPlay = autoPlayState.allow;
                    muted = muted || autoPlayState.mute;
                }
                await loaderPermission(this, autoPlay);
            })
            .then(() => {
                if (cancelled) {
                    return;
                }
                this.addListenViewportStatus();
                this.vjsPlayer.muted(muted);
                if (isFirst) {
                    this.isFirst = false;
                    this.imaPack.setAutoPlayState({ autoplayAllowed: autoPlay, autoplayRequiresMuted: muted });
                    return loadVideo().then(() => this.unmeuntMask.create());
                }
            })
            .then(() => {
                if (cancelled) {
                    return;
                }
                this._container.classList.add("is-loading");
                let parts = getLiAdParts(this.liAd);
                this.liAd.unlock();
                if (this.inViewport || this.defOpt.preloadType == PRELOAD_TYPE.IMMED_AND_PLAY) {
                } else {
                    this.liAd.pause();
                    this.waitInViewport.promise().then(() => {
                        this.liAd.resume();
                    });
                }
                return this.liAd.combined(parts).start();
            })
            .then(() => {
                if (cancelled || isFirst) {
                    return;
                }
                return readyToLoadVideoPromise(this, mediaMode).then(loadVideo);
            })
            .then(() => {
                if (cancelled) {
                    return;
                }
                this.keyboradHandle.resume();
                this.uiDisable(false);
                this._container.classList.add("is-start");
                this._container.classList.remove("is-loading");

                let removeBackup = () => {
                    this._container.classList.remove("wait-click");
                    // this.fplayer.off(".backup");
                };

                //if(this.ignoreDocumentHiddenCheck == false && document[hidden]){
                //if(this.ignoreDocumentHiddenCheck == false && !checkInViewport(this.interObsProxy)){
                // workaround of ios resume hang issue:
                // contenet player hangs after viewport switch during preroll playing.
                if (this.platformInfo.isIOS && this.timerecord) this.vjsPlayer.currentTime(this.timerecord + 0.5);

                let startPlay = () => {
                    this.play();
                    super.trigger(EVENT.IMPRESSION);
                };
                if (this.ignoreDocumentHiddenCheck == false && !this.inViewport) {
                    removeBackup();
                    this.requestPauseAd();
                    this.waitInViewport.promise()
                        .then(startPlay);
                } else {
                    startPlay();
                }

                this.playedCount.start();
                this.handleContentPlayerEvents(true);
                checkAndRemoveCover.apply(this);
                this.liAd.logo().start().catch(e => !e.isWarning && console.error(e));
                this.liAd.pinAd().start().catch(e => !e.isWarning && console.error(e));
                this.queueNext();
            })
            .catch(e => {
                let isAbort = false;
                let isWarning = false;
                let message = "unknown";
                if (typeof e == "string") {
                    isAbort = "abort" == e.toLowerCase();
                    message = e;
                } else if (e instanceof Error) {
                    message = e.message;
                    isWarning = e.isWarning === true;
                }
                if (!isAbort && !isWarning) {
                    this.vjsPlayer.errorDisplay.fillWith(message);
                    this.vjsPlayer.errorDisplay.show();
                    this._container.classList.add("is-error");
                    console.error(e);
                } else {
                    console.warn(e.message);
                }
                if (isWarning) return;
                this.keyboradHandle.resume();
                this._container.classList.remove("is-loading");
                this._container.classList.remove("is-preparing");
                this.handleContentPlayerEvents(true);
                this.isFirst = isFirst;
                checkAndRemoveCover.apply(this);
                super.trigger(EVENT.ERROR, { error: e });
                this.queueNext();
            });

        this.setSrcThrottle();

        function loaderPermission(ctx, autoPlay) {
            if (!autoPlay) {
                return ctx.waitClick.promise();
            }
            let { preloadType, preloadThreshold, visibleThreshold } = ctx.defOpt;
            if (preloadType != PRELOAD_TYPE.THRESHOLD) {
                return Promise.resolve();
            }
            if (preloadThreshold == null || preloadThreshold == visibleThreshold) {
                return ctx.waitInViewport.promise();
            }
            return Promise.race([
                ctx.waitInViewport.promise(),
                new Promise(resolve => {
                    const observer = new IntersectionObserver(
                        entries => entries[0].isIntersecting && resolve(observer.disconnect()),
                        { threshold: preloadThreshold }
                    );
                    observer.observe(ctx.container);
                }),
            ]);
        }
        function readyToLoadVideoPromise(ctx, mediaMode) {
            if (ctx.inViewport || ctx.ignoreDocumentHiddenCheck) {
                return Promise.resolve();
            }
            if (mediaMode != "vod") {
                return ctx.waitInViewport.promise();
            }
            ctx.requestPauseAd();
            return ctx.waitClick.promise().then(() => {
                ctx.imageAd.closeNonLinearBanner();
            });
        }
        function getLiAdParts(liAd) {
            if (midrollBeforeStart) {
                return [liAd.midrolls({ duration: midrollBeforeStartDuration }), liAd.jingle()];
            }
            if (isWakeFromKeepPlayingAd) {
                return [];
            }
            let liAdQueue = liAd.getQueue();
            if (liAdQueue.length > 0) {
                return liAdQueue.slice();
            }
            return [liAd.prerolls(), liAd.jingle()];
        }
    }

    addListenViewportStatus() {
        if (!this.defOpt.decideVisible) return;
        super.on("in_viewport", this.onViewportChange.onInViewport);
        super.on("out_viewport", this.onViewportChange.onOutViewport);
    }

    removeListenViewportStatus() {
        if (!this.defOpt.decideVisible) return;
        super.off("in_viewport", this.onViewportChange.onInViewport);
        super.off("out_viewport", this.onViewportChange.onOutViewport);
    }

    onViewportChange() {
        let playerStatusChanged = false;
        let wasMuted = false;
        let _this = this;

        function onInViewport() {
            _this._container.classList.remove("waiting-enter-viewport");

            if (wasMuted) {
                // workaround to fix volume bar is 0 after visibility change.
                setTimeout(() => _this.vjsPlayer.muted(false), 100);
                wasMuted = false;
            }

            if (playerStatusChanged == false) return;
            playerStatusChanged = false;

            if (_this.inlinearAd == false) {
                // workaround of ios resume hang issue.
                // contenet player hangs after viewport switch.
                if (_this.platformInfo.isIOS) {
                    _this.vjsPlayer.currentTime(_this.timerecord);
                }
                requestAnimationFrame().then(() => _this.play());
            } else {
                _this.liAd.resume();
            }
        }

        function onOutViewport() {
            _this._container.classList.add("waiting-enter-viewport");

            if (!_this.vjsPlayer.muted()) {
                _this.vjsPlayer.muted(true);
                wasMuted = true;
            }

            // workaround of ios resume hang issue.
            // contenet player hangs after viewport switch.
            if (_this.platformInfo.isIOS) {
                _this.timerecord = _this.vjsPlayer.currentTime();
            }
            if (_this.inlinearAd == false) {
                if (!_this._container.classList.contains("is-start")) {
                    return;
                }
                if (!_this.platformInfo.isIOS && (_this.vjsPlayer.paused() == true || _this.vjsPlayer.isFullscreen() == true)) return;
                _this.keyboradHandle.stop();
                _this.handleContentPlayerEvents(false);
                _this.pause();
                _this.vjsPlayer.one("play", () => {
                    _this.keyboradHandle.resume();
                    _this.handleContentPlayerEvents(true);
                    _this.imageAd.closeNonLinearBanner();
                });
                playerStatusChanged = true;
            } else if (!_this.liAd.paused && _this.defOpt.allowAdPause) {
                _this.liAd.pause();
                playerStatusChanged = true;
            }
        }

        return {
            onInViewport: onInViewport,
            onOutViewport: onOutViewport
        };
    }

    waitInViewport() {
        let onInViewport = null;
        return {
            promise: () => {
                return new Promise(resolve => {
                    if (!this.defOpt.decideVisible || this.inViewport) {
                        resolve();
                        return;
                    }

                    if (
                        this.defOpt.interObsHosting == false &&
                        this.platformInfo.isIOSAndInIFrame &&
                        versionComparison(this.platformInfo.iosVersion, "12.1") < 0
                    ) {
                        resolve();
                        return;
                    }

                    if (onInViewport) {
                        this.off(EVENT.IN_VIEWPORT, onInViewport);
                    }

                    onInViewport = () => {
                        this._container.classList.remove("waiting-enter-viewport");
                        this.off(EVENT.IN_VIEWPORT, onInViewport);
                        resolve();
                    };
                    this._container.classList.add("waiting-enter-viewport");
                    this.on(EVENT.IN_VIEWPORT, onInViewport);
                });
            },
            cancel: () => {
                if (onInViewport) {
                    this.off(EVENT.IN_VIEWPORT, onInViewport);
                    onInViewport = null;
                }
            },
        };
    }

    waitClick() {
        let _resolve = () => { };
        let container = this._container;
        let removeListenUserAction = () => {
            container.classList.remove("wait-click");
            this.off(EVENT.CLICK, userAction);
        };
        let userAction = (e) => {
            this.imaPack.triggerUserAction();
            this.houseVideoAd.triggerUserAction();
            removeListenUserAction();
            _resolve();
        };
        return {
            promise: () => {
                return new Promise((resolve, reject) => {
                    _resolve = resolve;
                    container.classList.add("wait-click");
                    this.on(EVENT.CLICK, userAction);
                });
            },
            cancel: () => {
                _resolve = () => { };
                removeListenUserAction();
            }
        };
    }

    trigger() {
        return this;
    }

    // == Public ==
    setSrc({
        autoPlay = true,
        src,
        sessionId,
        startTime, // startTime 單位毫秒
        liadMeta,
        playAds,
        assetId,
        midrollTimeCodes = [],
        midrollTimecodeDuration = [],
        mediaMode = "vod", // "vod", "live", "simulation_live"
        keepPlayingAd = false,
        programEndTime = null,
        midrollBeforeStart = false,
        midrollBeforeStartDuration = 0,
        adUrlReplacement = [],
        muted,
        cover = null,
        caption = {},
        enableCountdown,
        hiddenCheck,
        companionAdSize,
        puid,
        eids,
        getHouseAdUrl,
        getAdUrl
    }) {

        if (
            this.isFirst == true &&
            this.platformInfo.isIOSAndInIFrame == true &&
            versionComparison(this.platformInfo.iosVersion, "12.1") < 0 &&
            this.defOpt.decideVisible == true &&
            this.defOpt.interObsHosting == false
        ) {
            autoPlay = false;
        }

        if (midrollTimeCodes == null) {
            midrollTimeCodes = [];
        }

        if (midrollTimecodeDuration == null) {
            midrollTimecodeDuration = [];
        }

        if (adUrlReplacement == null) {
            adUrlReplacement = [];
        }

        if (typeof startTime == "string") {
            startTime = parseInt(startTime);
        } else if (typeof startTime !== "number") {
            startTime = 0;
        }

        if (typeof caption == "string") {
            caption = { text: caption };
        }

        if (muted == undefined) {
            muted = this.vjsInfo.muted;
        }

        // == 單位轉換 ==
        let m2s = m => (m / 1000) >> 0;
        midrollTimeCodes = midrollTimeCodes.map(m2s);
        midrollTimecodeDuration = midrollTimecodeDuration.map(m2s);
        midrollBeforeStartDuration = m2s(midrollBeforeStartDuration);

        this.setSrcCore({
            autoPlay,
            assetId,
            liadMeta,
            playAds,
            programEndTime,
            src,
            sessionId,
            startTime,
            keepPlayingAd,
            mediaMode,
            midrollTimeCodes,
            midrollTimecodeDuration,
            midrollBeforeStart,
            midrollBeforeStartDuration,
            adUrlReplacement,
            muted,
            cover,
            caption,
            enableCountdown,
            hiddenCheck,
            companionAdSize,
            puid,
            eids,
            getHouseAdUrl,
            getAdUrl
        });
        return this;
    }

    play() {
        if (this.isStopped == true) return;
        this.vjsPlayer.playWrapper();
        if (this.clip.live) {
            this.vjsPlayer.currentTime(Number.MAX_SAFE_INTEGER);
        }
        this.queueNext();
    }

    pause() {
        if (this.isStopped == true) return;
        if (this.vjsPlayer.paused()) {
            this.queueNext();
        } else {
            this.vjsPlayer.pause();
            this.vjsPlayer.one('pause', () => this.queueNext());
        }
    }

    seek(time) { //startTime 單位毫秒
        if (this.isStopped == true) return;
        this.vjsPlayer.currentTime((time / 1000) >> 0);
        this.vjsPlayer.on("seeked", () => this.queueNext());
        if (!this.clip.live) this.requestMidroll();
    }

    fastForward(ms = 10000) {
        if (this.isStopped == true) return;
        let time = Math.min(((this.currentTime + ms) / 1000) >> 0, this.vjsPlayer.duration() - 1);
        this.vjsPlayer.currentTime(time);
        if (!this.clip.live) this.requestMidroll();
    }

    rewind(ms = 10000) {
        if (this.isStopped == true) return;
        let time = Math.max(((this.currentTime - ms) / 1000) >> 0, 0);
        this.vjsPlayer.currentTime(time);
        if (!this.clip.live) this.requestMidroll();
    }

    seekBarMoving(diff) {
        const { vjsInfo, vjsPlayer } = this;
        if (!vjsInfo.isSeeking) {
            vjsInfo.isSeeking = true;
            vjsInfo.seekTime = vjsPlayer.currentTime();
        }
        vjsInfo.seekTime = Math.min(Math.max(vjsInfo.seekTime + diff, 0), vjsPlayer.duration());
        vjsPlayer.controlBar.progressControl.seekBar.startSeeking(vjsInfo.seekTime / vjsPlayer.duration());
    }

    seekBarMoveEnd() {
        const { vjsInfo, vjsPlayer } = this;
        if (!vjsInfo.isSeeking) {
            return;
        }
        vjsPlayer.controlBar.progressControl.seekBar.stopSeeking();
        vjsInfo.seekTime = null;
        vjsInfo.isSeeking = false;
    }

    stop() {
        //TODO 檢查是否有 timer 沒清 //TODO 確認 stop 行為
        if (this.isStopped == true) return;

        this.uiDisable(true);
        this.inlinearAd = false;
        this.keyboradHandle.stop();
        this.unmeuntMask.remove();
        this.cleanQueue();
        this.inExecution = true;
        this.isStopped = true;
        this._container.classList.add("is-stopped");
        this.abortPreSrc();
        this.handleContentPlayerEvents(false);

        // let engineName = getEngineName(this);
        // if(typeof engineName !== "undefined" && engineName.indexOf("hlsjs") > -1){
        //     this.fplayer.engine.hls.stopLoad();
        // }

        this.vjsPlayer.pause();
        if (!this.clip.live || this.clip.dvr) {
            this.vjsPlayer.currentTime(0)
            this.vjsPlayer.one("seeked", () => {
                super.trigger(EVENT.STOP);
            });
        } else {
            super.trigger(EVENT.STOP);
        }
        this.pinAdController.close();
        this.liAd.reset();
        this.ssControl.stop();
        this.queueNext();
        this.fullscreen(false);
    }

    destroy() {
        //TODO 清除 instance
        super.trigger(EVENT.WILL_SHUTDOWN);
        if (this.isStopped == false) {
            this.stop();
        }

        if (this.viewportSensor) {
            this.viewportSensor.stop();
        }

        // let engineName = getEngineName(this);
        // if(typeof engineName !== "undefined" && engineName.indexOf("hlsjs") > -1){
        //     this.fplayer.engine.hls.destroy();
        // }

        this.vjsPlayer.dispose();
        // this.fplayer.shutdown();
        this.liAd.reset(true);
        this.fullscreen(false);
    }

    mute(flag) {
        this.imaPack.mute(flag);
        this.houseVideoAd.mute(flag);
        this.vjsPlayer.muted(flag);
        return this;
    }

    volume(level) {
        this.vjsPlayer.volume(level);
        return this;
    }

    fullscreen(flag) {
        if (flag === this.vjsPlayer.isFullscreen()) return this;

        let oriDisabled = this.vjsPlayer.controls();
        if (oriDisabled) {
            this.uiDisable(false);
        }
        this.vjsPlayer.fullscreen();
        if (oriDisabled) {
            this.uiDisable(true);
        }
        return this;
    }

    stopLinearAd() {
        this.liAd.stopLinearAd();
    }

    on() {
        super.on.apply(this, arguments);
        return this;
    }

    one() {
        super.one.apply(this, arguments);
        return this;
    }

    off() {
        super.off.apply(this, arguments);
        return this;
    }

    //for testing
    addCount(key, count) {
        return this.playedCount.addCount(key, count);
    }

    //for testing
    getAllCount() {
        return this.playedCount.getAllCount();
    }

    //for testing
    // getEngineName(){
    //     return this.fplayer.engine.engineName;
    // }

    //for testing
    getLiAdMeta(details) {
        if (!details) {
            return this.liAd.oriLiadMeta;
        }
        let { oriLiadMeta, elements, parts, midrollTimeCodes, role } = this.liAd;
        return {
            oriLiadMeta: oriLiadMeta,
            elements: elements,
            parts: parts,
            midrollTimeCodes: midrollTimeCodes,
            role: role
        };
    }

    //for testing
    displayTimeline() {
        this._container.classList.toggle("fix_controls");
    }

    setVpaidModeToInsecure() {
        this.imaPack.setVpaidModeToInsecure();
    }

    setVpaidModeToEnable() {
        this.imaPack.setVpaidModeToEnable();
    }

    setVpaidModeToPreset() {
        this.imaPack.setVpaidModeToPreset();
    }

    getVpaidMode() {
        return this.imaPack.getVpaidMode();
    }

    getAllPlayerVolumeLevel() {
        return {
            content: this.fplayer.volumeLevel,
            ima: this.imaPack.getVolume(),
            houseAd: this.houseVideoAd.getVolume()
        };
    }

    getCurrentExternalAdInfo() {
        return this.liAd.getCurrentExternalAdInfo();
    }

    sendCurrentExternalAdInfo(event) {
        return this.liAd.sendCurrentExternalAdInfo(this.defOpt.appInfo, event);
    }

    vastTest(vast, optional = {}) {
        return this.liAd.vastTest(vast, optional);
    }

    setCustomApiUrlConfig(config) {
        this.urlConfig.setConfig(config);
    }

    appendChild(dom) {
        return this.MainViewOperator.appendChild(dom);
    }

    viewportIn() {
        this.interObsProxy && this.interObsProxy.inViewport();
    }

    viewportOut() {
        this.interObsProxy && this.interObsProxy.outViewport();
    }

    get currentTime() {
        try {
            return this.vjsPlayer.currentTime() * 1E3;
        } catch (e) {
            return 0;
        }
    }

    get muted() {
        return this.vjsPlayer.muted();
    }

    get volumeLevel() {
        return this.vjsPlayer.volume();
    }

    get currentInfo() {
        return {
            percentage: ((this.currentTime / (this.vjsPlayer.duration() * 1E3) * 1E3) >> 0) / 1E3,
            played: this.playedCount.getMainCount()
        };
    }

    get duration() {
        return this.vjsPlayer.duration() * 1E3;
    }

    get container() {
        return this._container;
    }

    get paused() {
        if (this.inlinearAd) {
            return this.liAd.paused;
        } else {
            return this.vjsPlayer.paused();
        }
    }

}

Player.prototype.EVENT = Player.EVENT = EVENT;
Player.prototype.PRELOAD_TYPE = Player.PRELOAD_TYPE = PRELOAD_TYPE;
Trace(Player);

// function getEngineName(cxt){
//     return ["fplayer", "engine", "engineName"].reduce((pre, target) => {
//         if(typeof pre === "undefined") return undefined;
//         return pre[target];
//     }, cxt);
// }

function checkAndRemoveCover() {
    if (this._container.classList.contains("is-poster")) {
        this._container.style.removeProperty("background-image");
        this._container.classList.remove("is-poster");
    }
}

function UnmeuntMask(cxt) {
    let _this = cxt;
    let unumuteMask = null;

    function unumuteClick(e) {
        removeEvents();
        _this.mute(false);
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        removeUnmeuntMask();
    }

    function handleUnmute(e) {
        if (e.muted == true) return;
        removeEvents();
        removeUnmeuntMask();
    }

    function removeUnmeuntMask() {
        if (unumuteMask == null) return;
        unumuteMask.parentNode.removeChild(unumuteMask);
        unumuteMask = null;
    }

    function removeEvents() {
        _this.off(EVENT.MUTE, handleUnmute);
        if (unumuteMask == null) return;
        unumuteMask.removeEventListener("click", unumuteClick, true);
    }

    return {
        create() {
            unumuteMask = document.createElement("DIV");
            unumuteMask.classList.add("unumute");
            unumuteMask.innerText = "開啟音效";
            _this._container.appendChild(unumuteMask);
            unumuteMask.addEventListener("click", unumuteClick, true);
            _this.on(EVENT.MUTE, handleUnmute);
        },

        remove() {
            removeEvents();
            removeUnmeuntMask();
        }
    };
}

function vjsInfoBase() {
    const volumeStoreKey = "li_pl_vol";
    let { volume , muted } = getLocalVolumeInfo();

    return {
        get volume () {
            return volume;
        },
        get muted () {
            return muted;
        },
        saveVolume (newVolume, newMuted) {
            volume = newVolume;
            muted = newMuted
            try {
                localStorage.setItem(volumeStoreKey, JSON.stringify({ volume: newVolume, muted: newMuted }));
            } catch (e) {}
        },
        keyboard: true, 
    }; // inactivityTimeout: 2000

    function getLocalVolumeInfo () {
        try {
            let infoString = localStorage.getItem(volumeStoreKey);
            if (infoString) {
                return JSON.parse(infoString);
            }
        } catch (e) {}
        return { level: 1, muted: false };
    }
}