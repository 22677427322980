import fetch from 'unfetch';

export default function litvTracing(config, player){
    let against = config.sponsorInfo == "cG50" || config.sponsorInfo == "Y3Qy";
    let browserInfo = player.defOpt.appInfo.split("|");
    browserInfo = browserInfo[browserInfo.length - 1];
    let platform = (function(a) {
        if (/ANDROID|IPHONE|IPAD|IPOD/i.test(a)) {
            return "m-web";
        }else return "web";
    })(navigator.userAgent) + "|" + browserInfo;

    function cdiUrl(){
        return config.cdiApiUrls[(Math.random() * config.cdiApiUrls.length) | 0];
    }

    function acsUrl(){
        return config.acsUrls[(Math.random() * config.acsUrls.length) | 0];
    }

    function jsonRpcTemplate(params){
        return {
            id: Math.random() * 999 | 0,
            jsonrpc: "2.0",
            method: "YahooService.InsertYahooAdLog",
            params: params
        };
    }

    function combinationPara(cdata, clip){
        return JSON.stringify(jsonRpcTemplate({
            cdata: cdata,
            ad_clip: clip,
            ad_id: "",
            asset_id: "",
            platform: platform
        }));
    }

    function reportToCDI(body){
        fetch(cdiUrl(), {
            method: "POST",
            headers: {
                "Accept": "application/json, text/plain, */*",
                "Content-Type": "application/json"
            },
            body: body
        })
    }

    function heavyAdIntervention(inAd){
        let formData = new FormData();
        formData.append('partner', config.partnerName);
        formData.append('puid', config.puid);
        formData.append('in_ad', inAd ? "1" : "0");
        formData.append('time', typeof(performance) !== "undefined" ? (performance.now() | 0).toString() : "");
        navigator.sendBeacon(acsUrl() + "/rpc.php", formData);
    }

    // function beforeunload(inAd){
    //     if(!against) return;
    //     let formData = new FormData();
    //     formData.append('partner', "beforeunload");
    //     formData.append('puid', config.puid);
    //     formData.append('in_ad', inAd ? "1" : "0");
    //     formData.append('time', typeof(performance) !== "undefined" ? (performance.now() | 0).toString() : "");
    //     navigator.sendBeacon(acsUrl() + "/rpc.php", formData);
    // }

    function pageInit(){
        if(against) reportToCDI(combinationPara("request", "ams00112323"));
    }

    function pageImpression(){
        if(against) reportToCDI(combinationPara("impression", "ams00112323"));
    }

    return {
        heavyAdIntervention,
        pageInit,
        pageImpression
    };
}