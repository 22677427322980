export default function EarlyMidrollSetup(player, config, meta) {
    if (!config || !config.enable) {
        return;
    }
    const DEFAULT_TIME = 10;
    let timeOffset = (typeof config.time == 'number' ? config.time : DEFAULT_TIME) * 1e3;
    let earlyMidrollTime = -1;
    let prerollMissed = () => player.getAllCount().prerolls < 0;
    let onProgress = () => {
        if (earlyMidrollTime < player.currentTime) {
            stopOnProgress();
            player.requestMidroll(true);
        }
    };
    let stopOnProgress = () => player.off(player.EVENT.PROGRESS, onProgress);
    let onImpression = () => {
        stopOnProgress();
        if (prerollMissed()) {
            earlyMidrollTime = meta.startTime + timeOffset;
            player.on(player.EVENT.PROGRESS, onProgress);
        }
    };
    player.on(player.EVENT.IMPRESSION, onImpression);
}