import fetch from 'unfetch';
import AdsSessionChannel from './AdsSessionChannel.js';
import {LiAdErrorCodes} from './CustomError.js';
import {JsonrpcId} from './Util.js';

const TRIGGER_NAME = "HouseVideoAd";

export default class HouseVideoAd{
    constructor(container, contentPlayerProxy, platformInfo, urlConfig){
        this.container = container;
        this.contentPlayerProxy = contentPlayerProxy;
        this.adsSessionChannel = null;
        this.adsRequestAbort = () => {};
        // this.hls = null;
        this.player = null;
        // this.source = null;
        this.volume = -1;
        this.muted = false;
        this.isPaused = false;
        this.isStarted = false;
        this.triggerUserAction = this.triggerUserAction.bind(this);
        this.triggerPlay = this.triggerPlay.bind(this);
        this.onCanplay = this.onCanplay.bind(this);
        this.onEnded = this.onEnded.bind(this);
        this.onError = this.onError.bind(this);
        this.onTimeupdate = this.onTimeupdate.bind(this);
        this.onClickVideo = this.onClickVideo.bind(this);
        this.onPause = this.onPause.bind(this);
        this.onResume = this.onResume.bind(this);
        this.triggerPauseTimerId = null;
        this.enableCountdown = null;
        // this.playerEngines = null;
        this.urlConfig = urlConfig;
        this.getAdUrl = null;
        this.puid = "";
        // let ua = navigator.userAgent.toLowerCase();
        // this.needHlsJs = !(/iP(hone|od|ad)/i.test(ua) || (/Safari/.exec(ua) && !/Chrome/.exec(ua)));
        // this.useFlash = false;
        // if(this.needHlsJs == true && (!window.Hls.isSupported() || platformInfo.isXP || platformInfo.isVista)){
        //     this.useFlash = true;
        // }
        
        this.triggerUserAction = this.triggerUserAction();
        this.createPlayer(container);
    }

    createPlayer(container) {
        let vjsElt = container.appendChild(document.createElement("video"));
        vjsElt.className = "video-js";
        vjsElt.controls = false;
        vjsElt.disablePictureInPicture = true;

        let player = videojs(vjsElt, {
            posterImage: false,
            playsinline: true,
            textTrackDisplay: false,
            loadingSpinner: false,
            bigPlayButton: false,
            controlBar: false,
            errorDisplay: false,
            textTrackSettings: false,
            aspectRatio: '16:9'
        });
        player.addClass("house_video");
        player.on("canplay", this.onCanplay);
        player.on("ended", this.onEnded);
        player.on("error", this.onError);
        player.on("timeupdate", this.onTimeupdate);
        player.on("click", this.onClickVideo);
        player.on("pause", this.onPause);
        player.on("play", this.onResume);
        player.el_.oncontextmenu = e => e.preventDefault();

        this.player = player;
    }
    
    setUpVolume(){
        this.muted = this.contentPlayerProxy.muted;
        this.volume = this.muted ? 1 : this.contentPlayerProxy.volume;
        let volume = this.muted ? 0 : this.volume;

        this.player.volume(volume);
        this.player.muted(this.muted);
    }

    setGetHouseAdUrl(getHouseAdUrl) {
        this.getHouseAdUrl = getHouseAdUrl;
    }

    setGetAdUrl(getAdUrl){
        this.getAdUrl = getAdUrl;
    }

    setPuid(puid){
        this.puid = puid;
    }
    
    onCanplay(){
        this.triggerPlay();
    }
    
    triggerPlay(){
        if(this.isPaused == false){
            this.play();
        }
    }
    
    play(){
        if(!document.body.contains(this.player.el())){
            return;
        }
        //正片已經檢查過自動播放，故此處無需再走測試自動播放的流程
        this.isStarted = true;
        let played = this.player.play();
        if(typeof played !== "undefined" && typeof played.then === "function"){
            played
                .then(() => this.onPlaySuccess())
                .catch(e => {
                    console.warn("played fail", e);
                    this.adsSessionChannel.trigger("Error", { error : Object.assign({innerError : e}, LiAdErrorCodes.codes.HOUSE_AD_VIDEO_ERROR)}, TRIGGER_NAME);
                });
        }else{
            this.onPlaySuccess();
        }
    }
    
    onPlaySuccess(){
        this.container.classList.add("ad_impression");
        let duration = this.player.duration() >> 0;
        this.adsSessionChannel.trigger("PauseRequested", {}, TRIGGER_NAME);
        this.adsSessionChannel.trigger("AdMediaStart", { duration: duration }, TRIGGER_NAME);
        this.adsSessionChannel.trigger("Impression", {}, TRIGGER_NAME);
        if(this.enableCountdown == true){
            this.adsSessionChannel.trigger("CountdownStart", { time: duration, clickFunction: () => this.adsSessionChannel.trigger("ClickSkip", {}, TRIGGER_NAME) });
        }
    }
    
    onEnded(){
        clearInterval(this.triggerPauseTimerId);
        if(this.enableCountdown == true){
            this.adsSessionChannel.trigger("CountdownEnd");
        }
        this.adsSessionChannel.trigger("Completed", {}, TRIGGER_NAME);
        this.adsSessionChannel.trigger("AdMediaComplete", {}, TRIGGER_NAME);
        this.stopPlayer();
        this.adsSessionChannel.destroy();
        this.adsSessionChannel = null;
    }
    
    onError(e){
        let errorInfo = { error: Object.assign({innerError : e.target.error}, LiAdErrorCodes.codes.HOUSE_AD_VIDEO_ERROR) };
        let fatal = (((e || {})["target"] || {})["error"] || {})["fatal"];
        errorInfo.fatal = fatal;
        
        if(fatal === false){
            console.warn("onError", e);
            if(this.adsSessionChannel !== null){
                this.adsSessionChannel.trigger("Error", errorInfo, TRIGGER_NAME);
            }
        }else{
            console.error("onError", e);
            this.stopPlayer();
            if(this.adsSessionChannel !== null){
                this.adsSessionChannel.trigger("Error", errorInfo, TRIGGER_NAME);
                this.adsSessionChannel.trigger("AdMediaComplete", {}, TRIGGER_NAME);
                this.adsSessionChannel.destroy();
                this.adsSessionChannel = null;
            }
        }
    }
    
    stopPlayer(destroy){
        if(this.player == null) return false;
        this.player.pause();
        this.player.reset();
        this.container.classList.remove("ad_impression");
        return true;
    }
    
    onTimeupdate(){
        if(!this.enableCountdown || !this.adsSessionChannel) return;
        this.adsSessionChannel.trigger("CountdownProgress", { time: (this.player.duration() - this.player.currentTime()) >> 0 });
    }
    
    onClickVideo(e){
        if(this.player.paused()){
            //this.player.play();
            this.adsSessionChannel.trigger("RequestResume", {}, TRIGGER_NAME);
        }else{
            let clickThrough = this.player.contentEl().dataset.clickThrough;
            if(typeof clickThrough !== "undefined" && clickThrough !== ""){
                window.open(clickThrough, '_blank').focus();
            }
            this.adsSessionChannel.trigger("Click", e, TRIGGER_NAME);
        }
    }

    onPause(e){
        this.triggerPauseTimerId = setTimeout(() => {
            if(this.adsSessionChannel) this.adsSessionChannel.trigger("Pause", {innerEvent: e}, TRIGGER_NAME);
        }, 12);
    }

    onResume(e){
        if(this.adsSessionChannel) this.adsSessionChannel.trigger("Resume", {innerEvent: e}, TRIGGER_NAME);
    }
    
    getAdURLs(assetId){
        if(this.getHouseAdUrl){
            return this.getHouseAdUrl(assetId).catch((ex) => {
                throw new Error(ex);
            });
        }else if(this.getAdUrl){
            return this.getAdUrl(assetId).then(response => {
                if (!response.ok) throw new Error(response.statusText);
                return response.json();
            }).then(response => {
                return {
                    url: response.fullpath, 
                    more: {
                        AssetURLs: [response.fullpath],
                        PlayAds: response.playAds,
                        SessionId: response.sessionId,
                        SubtitleURLs: [response.subtitles]
                    }
                };
            }).catch((ex) => {
                throw new Error(ex);
            });
        }
        else{
            return fetch(this.urlConfig.cdiProxy, {
                method: "POST",
                headers: {
                    "Accept": "application/json, text/plain, */*",
                    "Content-Type": "application/json"
                },
                body:JSON.stringify({
                    "id": JsonrpcId.get(),
                    "jsonrpc": "2.0",
                    "method": "LoadService.GetAdURLs",
                    "params": {
                        "AssetId": assetId,
                        "DeviceType": "pc",
                        "puid": this.puid
                    }
                })
            })
            .then(response => {
                if (!response.ok) throw new Error(response.statusText);
                return response.json();
            })
            .then(response => {
                if(response.error){
                    let error = new Error(response.error.message);
                    error.code = response.error.code;
                    throw error;
                }
                return {
                    url: response.result.AssetURLs[0], 
                    more: response.result
                };
            }); //TODO 不只取第 0 筆
        }
    }
    
    adsRequest(data){
        this.adsRequestAbort();
        this.setUpVolume();
        
        this.player.contentEl().dataset.clickThrough = data.click_through || "";
        this.enableCountdown = data.enable_countdown;
        let assetId = data.data;
        this.adsSessionChannel && this.adsSessionChannel.destroy();
        this.adsSessionChannel = new AdsSessionChannel();
        
        let cancelled = false;
        this.adsRequestAbort = () => {
            cancelled = true;
        };
        
        Promise.resolve().then(() => {
            this.getAdURLs(assetId)
            .then((result) => {
                if(cancelled) return;
                this.adsSessionChannel.trigger("Loaded", { meta: result.more }, TRIGGER_NAME);
                this.load(result.url);
                this.play();
            })
            .catch(error => {
                console.log(error);
                this.adsSessionChannel.trigger("Error", { error: Object.assign({innerError : error}, LiAdErrorCodes.codes.GET_HOUSE_AD_URL_ERROR) });
            });
            this.adsSessionChannel.trigger("Request", {requestInfo: {assetId: assetId}}, TRIGGER_NAME);
        });
        return this.adsSessionChannel.getChannel();
    }
    
    load(url){
        this.player.src(url);
    }
    
    pause(){
        this.isPaused = true;
        if(this.isStarted == true){
            this.player.pause();
        }
    }
    
    resume(){
        this.isPaused = false;
        if(this.isStarted == false){
            this.play();
        }else{
            this.player.play();
        }
    }
    
    stop(destroy){
        this.adsRequestAbort();
        if(this.stopPlayer(destroy) && this.adsSessionChannel != null){
            this.adsSessionChannel.trigger("Abort");
        }
    }
    
    mute(muted){
        if(muted === this.muted) return;
        this.muted = muted;
        if(this.player == null) return;
        if(muted == true){
            this.volume = this.player.volume();
            this.player.volume(0);
        }else{
            this.player.volume(this.volume);
        }
        this.player.muted(muted);
    }
    
    setVolume(volume){
        this.volume = volume;
        if(this.player == null) return;
        this.player.volume(volume);
    }
    
    getVolume(){
        if(this.player == null) return this.volume;
        return this.player.volume();
    }
    
    triggerUserAction(){
        let exed = false;
        return function(){
            if(exed == true) return;
            exed = true;
            let p = this.player.play();
            if(p && typeof p.catch == "function"){
                p.catch(e => console.warn(e));
            }
        };
    }
    
}