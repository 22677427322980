import { mobilecheck } from "../Util/Util.js";
import ErrorComponent from "./Error.js";

const errorVideo = "https://lispeedtest.akamaized.net/ppninstead/index.m3u8";
export default class ContentManager{
    constructor(meta, config, api, player, recommend, obeyPlayBehavior, errorComponent, $content, $title){
        this.meta = meta;
        this.config = config;
        this.api = api;
        this.player = player;
        this.recommend = recommend;
        this.obeyPlayBehavior = obeyPlayBehavior;
        this.$content = $content;
        this.$title = $title;
        this.errorComponent = errorComponent;
        // this.count = 0;
        this.refreshP;
        this.refresh = this.refresh.bind(this);
        this.playNext = this.playNext.bind(this);
        this.play = this.play.bind(this);
        this.muted = true;
        this.isErrorMode = false;
        this.firstErrorAssetId = null;
    }

    setMuted(muted){
        this.muted = muted;
    }

    refresh(contentInfo){
        this.refreshP = new Promise(async resolve => {
            let metaR;
            try {
                this.resetErrorMode();
                metaR = await this.meta.next(contentInfo);
            } catch (error) {
                //console.log(error);
                //TODO send error log to server
                //this.errorComponent.resolve(error, ErrorComponent.ERROR_SOURCE.META);
                this.setErrorMode();
                resolve({
                    done: false
                });
                return;
            }
            
            if(metaR.done == false){
                this.$title.innerText = this.meta.secondaryTitle;
                this.$title.href = this.meta.bottomLink || "";
            }
            this.player.countdownComponent.setThumbnail(this.meta.image); //XXX 做法不太好。待 litvplayer 開放相關的 API。
            resolve(metaR);
        });
        return this.refreshP;
    }

    play(contentInfo) {
        this.refresh(contentInfo);
        this.playNext();
    }

    playNext(isRetry){
        this.refreshP.then(async metaR => {
            // if(isRetry != true && ++this.count > this.config.countLimit){
            //     location.reload();
            //     return;
            // }
    
            // if(metaR.done == true){
            //     this.recommend.open();
            //     return;
            // }
            
            let autoPlay = this.obeyPlayBehavior.calcAutoPlay();
            let contentUrl;
            
            if(this.isErrorMode) {
                contentUrl = errorVideo;
            } else if(this.config.getUrls == "fixedly"){
                contentUrl = this.meta.streamUrl;
            }else{
                try {
                    contentUrl = await this.getContentUrl(this.meta.assetsId);
                } catch (error) {
                    if(error.isWarning == true){
                        throw error;
                    }
                    contentUrl = errorVideo;
                    this.setErrorMode();
                }
            }
            //let contentUrl = config.getUrls == "fixedly" ? meta.streamUrl : await getContentUrl(meta.assetsId);
            //console.log(this.meta.image);

            this.player.setSrc({
                src: contentUrl,
                startTime: this.isErrorMode || this.meta.startTime,
                mediaMode: this.config.playerMediaMode,
                assetId: this.meta.assetsId,
                caption: this.isErrorMode || (this.config.insideTitle !== false && { text: this.meta.secondaryTitle, link: this.meta.insideTitleLink }),
                cover: this.isErrorMode || this.meta.image,
                enableCountdown: true,
                midrollTimeCodes: this.meta.midrollTimeCodes,
                muted: this.muted,
                playAds: true,
                autoPlay: autoPlay,
                liadMeta: this.meta.liad,
                hiddenCheck: true,
                companionAdSize: [{ width: 300, height: 250 }],
                puid: this.config.puid,
                keepPlayingAd: true
            });

            if(mobilecheck()) this.player.countdownComponent.setThumbnail(""); //XXX
        })
        .catch(e => {});
    }

    getContentUrl(assetId){
        return this.api.getContentUrl(assetId)
        .then(r => {
            this.firstErrorAssetId = null;
            return r;
        })
        .catch(error => {
            if(error.code == 87 || error.code == 42000087){
                this.errorComponent.resolve(error, ErrorComponent.ERROR_SOURCE.GET_URL);
                throw error;
            }
    
            if(this.firstErrorAssetId == assetId){
                this.firstErrorAssetId = null;
                //errorComponent.resolve(error, ErrorComponent.ERROR_SOURCE.GET_URL);
                throw error;
            }
    
            if(this.firstErrorAssetId == null){
                this.firstErrorAssetId = assetId;
            }
    
            console.log("@@ find next @@");
            Promise.resolve().then(() => {
                this.play();
            });
            let err = new Error("abort");
            err.isWarning = true;
            throw err;
        });
    }

    setErrorMode(){
        this.$content.classList.add("ppn-error_mode");
        this.isErrorMode = true;
    }

    resetErrorMode(){
        this.$content.classList.remove("ppn-error_mode");
        this.isErrorMode = false;
    }
}