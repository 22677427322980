import fetch from 'unfetch';
import ObserverPattern from './ObserverPattern.js';
import {browserInfo, JsonrpcId} from './Util.js';

export default class Report extends ObserverPattern{
    constructor(urlConfig){
        super();
        this.urlConfig = urlConfig;
        //TODO inner error        
        let platform = (function(a) {
            if (/ANDROID|IPHONE|IPAD|IPOD/i.test(a)) {
                return "m-web";
            }else return "web";
        })(navigator.userAgent);
        
        this.postData = this.postData({platform: platform  + "|" + browserInfo}).bind(this);
    }
    
    createLogger(assetId, spaceId, unitId, title, puid, partTypeInfo){
        return new Logger(assetId, spaceId, unitId, title, puid, partTypeInfo, this.postData);
    }

    postData(info){
        return function(params, partTypeInfo){
            params = Object.assign({} , info, params);
            this.trigger("Report", {reportData: params, partTypeInfo: partTypeInfo || {}});
            let url = this.urlConfig.cdiApi;
            if(!url) return;
            return fetch(url, {
                method: "POST",
                headers: {
                    "Accept": "application/json, text/plain, */*",
                    "Content-Type": "application/json"
                },
                body:JSON.stringify({
                    "id": JsonrpcId.get(),
                    "jsonrpc": "2.0",
                    "method": "YahooService.InsertYahooAdLog",
                    "params": params
                })
            });
        }
        
    }

    postDebug(adInfo, adElement, appInfo, triggerEvent){
        return new Promise((resolve, reject) => {
            if(adInfo == null){
                console.log("LiTVPlayer postDebug reject: adInfo is null");
                reject("adInfo is null");
                return;
            }

            try {
                if(typeof triggerEvent === "undefined" || triggerEvent.isTrusted != true){
                    appInfo += "*";
                }
            } catch (error) {}

            adElement = adElement || {};
            let data = {
                "swver": appInfo,
                "unit_id": adElement.unit_id || "",
                "ad_system": adInfo.adSystem || "",
                "duration": adInfo.duration || 0,
                "click_url": adInfo.clickThroughUrl || "",
                "media_url": adInfo.mediaUrl || ""
            };
            console.log("LiTVPlayer postDebug", data, adInfo, adElement, appInfo);
            let url = this.urlConfig.adDebug;
            if(!url) {
                resolve();
                return;
            }
            fetch(url, {
                method: "POST",
                headers: {
                    "Accept": "application/json, text/plain, */*",
                    "Content-Type": "application/json"
                },
                body:JSON.stringify({
                    "id": JsonrpcId.get(),
                    "jsonrpc": "2.0",
                    "method":"report_debug",
                    "params": data
                })
            })
            .then((response) => {
                if(response.ok == true) resolve();
                else reject(response.statusText);
            })
            .catch(reject);
        });        
    }
}

class Logger{
    constructor(assetId, spaceId, unitId, title, puid, partTypeInfo, postData){
        this.spaceId = spaceId;
        this.assetId = assetId;
        this.unitId = unitId;
        this.title = title;
        this.puid = puid;
        this.partTypeInfo = partTypeInfo;
        this.postData = postData;
    }
    
    reportToLitv(cdata, code){
        let params = {
            cdata: cdata,
            ad_id: this.unitId,
            ad_clip: this.spaceId + ((code || "") && "|" + code),
            asset_id: this.assetId,
            title: this.title,
            puid: this.puid
        };
        this.postData(params, this.partTypeInfo);
    }
    
    request(){
        this.reportToLitv("request");
        return this;
    }
    
    serve(){
        this.reportToLitv("serve");
        return this;
    }
    
    impression(){
        this.reportToLitv("impression");
        return this;
    }

    complete(){
        this.reportToLitv("complete");
        return this;
    }
    
    click(){
        this.reportToLitv("click");
        return this;
    }
    
    error(liadError){
        let cdata = liadError.reportType;
        if(cdata == "warning") return this;
        let code = ["noAd", "duplicateAd"].includes(cdata) ? null : liadError.code;
        this.reportToLitv(cdata, code);
        return this;
    }

    break(){
        this.reportToLitv("break");
        return this;
    }
    
    imgClick(){
        this.reportToLitv("imgClick");
        return this;
    }
    
    imgImpression(){
        this.reportToLitv("imgImpression");
        return this;
    }
    
    vipSkip(){
        this.reportToLitv("vipSkip");
        return this;
    }
    
    videoClick(){
        this.reportToLitv("videoClick");
        return this;
    }
    
    videoImpression(){
        this.reportToLitv("videoImpression");
        return this;
    }

    adLoaded(time){
        this.reportToLitv("adload", time);
        return this;
    }

    adDurationFetched(duration){
        this.reportToLitv("adDurationFetched", duration);
        return this;
    }
    
    destroy(){
        this.postData = null;
    }
}