import fetch from 'unfetch';
import ObserverPattern from './ObserverPattern.js';

export default class StreamingSessionControl extends ObserverPattern {
    
    constructor(checkContinueStream = null, stopContinueStream = null){
        super();
        if(typeof checkContinueStream === "function"){
            let isPromiseChecked = false;
            this._pContinueSm = (sessionId) => {
                return new Promise((resolve, reject) => {
                    if(isPromiseChecked == true){
                        checkContinueStream(sessionId).then(resolve).catch(reject);
                    }else{
                        let checkContinueStreamPromise = checkContinueStream(sessionId);
                        try{
                            if(typeof checkContinueStreamPromise.then === "function"){
                                checkContinueStreamPromise.then(resolve).catch(reject);
                                isPromiseChecked = true;
                            }else{
                                this.trigger("typeError");
                            }
                        }catch(e){
                            this.trigger("typeError");
                        }
                    }
                });
            };
        }else{
            this.start = this.stop = this.pause = () => {};
            return;
        }
        
        if(stopContinueStream == null){
            this._pStopSm = () => {
                return Promise.resolve();
            };
        }
        
        this._skip = false;
        this._nowSessionId = "";
        this._checkTimerId = null;
        this._checkPointTime = 0;
        this._maxRecheck = 0;
        this._errorCheckTime = 60000;
        this._abortCheckContinue = () => {};
        this._abortStopContinue = () => {};
    }
    
    _stopTimer(){
        if(this._checkTimerId != null) {
			clearTimeout(this._checkTimerId);
            this._checkTimerId = null;
		}
    }
    
    _publishError(reason){
        this.trigger("error", { reason : reason, sessionId : this._nowSessionId });
    }
    
    _checkContinue(){
        let isAbort = false;
        this._abortCheckContinue = () => {
            isAbort = true;
        };
        this._checkPointTime = +new Date();
        this._pContinueSm(this._nowSessionId)
        .then(response => {
            if(isAbort == true) return;
            this._stopTimer();
            if(response.continueFlag == true){
                this._prepareNextCheck(false, response)
                .then(() => {
                    this._checkContinue();
                });
            }else{
                this._publishError(response.reason);
            }
        })
        .catch(e => {
            if(isAbort == true) return;
            this._stopTimer();
            this._prepareNextCheck(true)
            .then(() => {
                this._checkContinue();
            });
        });
    }
    
    _prepareNextCheck(isError, config){
        return new Promise(resolve => {
            let time = 1;
            if(!isError){
                let nowTime = +new Date();
                this._errorCheckTime = this._checkPointTime + this._maxRecheck - nowTime;
                if(this._errorCheckTime < 100) this._errorCheckTime = 100;
                this._maxRecheck = config.maxRecheck * 1000;
                time = this._checkPointTime + (config.minRecheck * 1000) - nowTime;
                if(time < 1) time = 1;
            }else{
                time = this._errorCheckTime;
                this._errorCheckTime = 60000;
            }
            setTimeout(resolve, time);
        });
    }
    
    _stopContinue(){
        let isAbort = false;
        this._abortStopContinue = () => {
            isAbort = true;
        };
        return new Promise(resolve => {
            if(this._nowSessionId == ""){
                resolve();
                return;
            }
            this._pStopSm(this._nowSessionId).then(() => {
                if(isAbort == true) return;
                resolve();
            }).catch(e => {
                if(isAbort == true) return;
                console.warn(e);
                resolve();
            });
        });
    }
    
    _checkSessionIdAndRun(sessionId){
        return new Promise((resolve, reject) => {
            this._stopTimer();
            if(this._nowSessionId != "" && this._nowSessionId != sessionId){
                this._stopContinue().then(resolve);
            }else{
                resolve();
            }
        });
    }
    
    start(sessionId){
        if(this._skip == true) return;
        this._errorCheckTime = 60000;
        this._abortCheckContinue();
        this._checkSessionIdAndRun(sessionId)
        .then(() => {
            if(!sessionId) return;
            this._nowSessionId = sessionId;
            this._checkContinue();
        });
    }
    
    stop(){
        if(this._skip == true) return;
        this._checkSessionIdAndRun("")
        .then(() => {
            this._stopContinue();
        });
    }
    
    pause(){
        this._stopTimer();
        this._abortCheckContinue();
    }
    
    skip(skipCheck){
        if(skipCheck == true && this._skip == false){
            this.pause();
            this._stopContinue();
            this._nowSessionId = "";
            this._skip = true;
        }else if(skipCheck == false){
            this._skip = false;
        }
    }
}